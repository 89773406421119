<template>
  <div class="play__responses">
    <div v-for="player in players" v-bind:response="submissions.get(player)" v-bind:key="player" class="play__response"
      :class="[submissions.get(player) && 'play__response__ready', readyForJudgement && 'readyForJudgement']">
      <button v-if="readyForJudgement" @click="submit(player)" class="gallery__text"
        :data-ga-winner-text="submissions.get(player)">
        <span class="play__text-submission">{{ submissions.get(player) }}</span>
      </button>
      <div v-else>
        <template v-if="submissions.get(player)">
          <p class="play__text-submission">{{ submissions.get(player) }}</p>
        </template>
        <template v-else>
          <IconEllipsis />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import IconEllipsis from "../../icons/IconEllipsis";
import { audioPlayer } from "/src/soundfx/audioPlayer.js";

export default {
  name: "TextJudgement",
  mixins: [audioPlayer],
  components: {
    IconEllipsis,
  },
  props: {
    players: Array,
    submissions: Object,
    readyForJudgement: Boolean,
    submit: Function,
  },
  data() {
    return {
      splashShown: true,
      splashTimer: 5000,
    };
  },
  mounted() {
    setTimeout(() => {
      this.splashShown = false;
    }, this.splashTimer);
  },
  watch: {
    submissions: function () {
      this.playSound(this.soundConsts.OTHERS_SUBMISSION)
    },
  }
};
</script>

<style lang="scss">
.play {
  display: flex;
  flex-direction: column;
  padding-bottom: 0 !important;
}

.play-judge {
  .play__responses {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;
    max-height: 560px;
    margin: 0 auto;
    flex: 1 1 auto;

    @media only screen and (min-width: 701px) {
      justify-content: center;
    }
  }

  .play__response {
    width: calc(50% - var(--spacing));
    margin: 0 var(--spacing-small) var(--spacing);
    font-size: 14px;
    opacity: 0.5;
    max-width: 321px;

    @media only screen and (min-width: 701px) {
      width: calc(33% - var(--spacing));
      margin: 0 var(--spacing-small) var(--spacing);
    }

    &.play__response__ready {
      opacity: 0.74;

      &.readyForJudgement {
        opacity: 1;

        >button {
          box-shadow: 0 0 0 2px var(--navy);
        }
      }
    }

    >div,
    >button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: var(--spacing-small);
      margin: 0;
      border-radius: var(--radius-small);
    }
  }

  .play__response:first-child:nth-last-child(n + 2),
  .play__response:first-child:nth-last-child(n + 2)~div {
    flex-basis: 100%;
    height: 45%;

    @media only screen and (min-width: 701px) {
      flex-basis: 45%;
      height: 100%;
      max-height: 245px;
      max-width: 400px;
    }
  }

  .play__response:first-child:nth-last-child(n + 3),
  .play__response:first-child:nth-last-child(n + 3)~div {
    flex-basis: 41%;

    @media only screen and (min-width: 701px) {
      height: 44%;
    }
  }

  .play__response:first-child:nth-last-child(n + 5),
  .play__response:first-child:nth-last-child(n + 5)~div {
    flex-basis: 41%;
    height: 29%;
    margin: 0 var(--spacing-small) 15px;

    @media only screen and (min-width: 701px) {
      height: 42%;
      flex-basis: 28%;
    }
  }
}

.header {
  flex: 0 1 auto;
}

.play__text {
  text-align: center;
  margin-bottom: 20px;
  transition: all 0.33s $ease-3 0.33s;

  @media only screen and (min-width: 701px) {
    margin-top: 40px;
  }
}

.judge {
  transition: all 1s $ease-3;
}

.judge__splash {
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);

  p {
    display: inline-block;
  }
}

.sticker-regular.splash-avatar {
  width: 300px;
}

.splashJudge-enter-from {
  opacity: 0;

  .splash-avatar,
  p {
    opacity: 0;
    transform: scale(1.5);
  }
}

.splashJudge-enter-active,
.splashJudge-leave-active {
  transition: opacity 0.33s $ease-3;

  .splash-avatar,
  p {
    transition: all 0.16s $ease-3;
  }
}

.splashJudge-enter-to,
.splashJudge-leave-from {
  opacity: 1;
  flex: 1;

  .splash-avatar,
  p {
    opacity: 1;
    transform: scale(1);
  }
}

.splashJudge-leave-to {
  opacity: 0;

  .splash-avatar,
  p {
    transform: scale(0);
  }
}

.mainJudge-enter-from,
.mainJudge-leave-to {
  opacity: 0;

  .play__text {
    opacity: 0;
    transform: translateY(60px);
  }

  .judge {
    transform: translateX(110%);
  }
}

.mainJudge-enter-active,
.mainJudge-leave-active {
  transition: opacity 0.16s $ease-3 0.33s;
}

.mainJudge-enter-to,
.mainJudge-leave-from {
  opacity: 1;

  .play__text {
    opacity: 1;
    transform: translateY(0);
  }

  .judge {
    transform: translateX(0);
  }
}
</style>
