<template>
  <div class="gif-gallery__responses">
    <div v-for="player in players" v-bind:key="player" class="gif-gallery__response">
      <div class="gif-gallery__response-inner">
        <div v-if="submissions.get(player)" class="gif-gallery__gif-container">
          <img :src="submissions.get(player)" class="gif-gallery__gif" />
        </div>
        <div v-else class="gif-gallery__placeholder">
          <IconEllipsis />
        </div>
        <Avatar :avatar-id="playerAvatars.get(player)" class="gif-gallery__avatar" />
      </div>
    </div>
  </div>
</template>

<script>
import IconEllipsis from "../../icons/IconEllipsis";
import Avatar from "../../Avatar";
import { audioPlayer } from "/src/soundfx/audioPlayer.js";

export default {
  name: "GifGallery",
  mixins: [audioPlayer],
  components: {
    Avatar,
    IconEllipsis,
  },
  props: {
    players: Array,
    playerAvatars: Object,
    submissions: Object,
  },
  watch: {
    submissions: function () {
      this.playSound(this.soundConsts.OTHERS_SUBMISSION)
    },
  }
};
</script>

<style lang="scss">
#gallery {
  padding-bottom: 50px;
}

#gallery__answers {
  width: 80%;
  margin: 0 auto;
}

/* GIF GALLERY */
#gif-gallery {
  padding-bottom: 50px;
}

.gif-gallery__responses {
  width: 100%;
  flex: 1 1 auto;
  max-height: 560px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;

  @media only screen and (min-width: 701px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.gif-gallery__response {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 35%;
  height: 45%;
  max-width: 326px;

  margin-bottom: 25px;

  @media only screen and (min-width: 700px) {
    width: 30%;
    margin: 0 15px;
    max-width: 400px;
  }
}

.gif-gallery__response:first-child:nth-last-child(n + 2),
.gif-gallery__response:first-child:nth-last-child(n + 2)~div {
  flex-basis: 100%;

  @media only screen and (min-width: 700px) {
    flex-basis: 45%;
    height: 50%;
    max-height: 245px;
    min-height: 170px;
  }
}

.gif-gallery__response:first-child:nth-last-child(n + 3),
.gif-gallery__response:first-child:nth-last-child(n + 3)~div {
  flex-basis: 46%;
  margin-left: 10px;
  height: 45%;
  min-height: 0px;


  @media only screen and (min-width: 700px) {
    flex-basis: 46%;
  }
}

.gif-gallery__response:first-child:nth-last-child(n + 5),
.gif-gallery__response:first-child:nth-last-child(n + 5)~div {
  flex-basis: 46%;
  min-height: 0px;
  margin-bottom: 12px;
  height: 32%;

  @media only screen and (min-width: 701px) {
    flex-basis: 29%;
    max-height: none;
    height: 46%;
    margin-left: 0px;
  }
}



.gif-gallery__response-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.gif-gallery__gif-container {
  width: 160px;
  margin-bottom: 25px;
}

.gif-gallery__not-ready {
  pointer-events: none;
}

.gif-gallery__gif {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--radius-small);
  object-fit: contain;
  background-color: var(--white);
  object-position: center;

  @media only screen and (min-width: 701px) {}
}

.gif-gallery__avatar {
  position: absolute;
  top: -12px;
  left: -12px;
  width: auto;
  height: 35px;
}

.gif-gallery__placeholder {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  margin-bottom: 25px;
  border-radius: var(--radius-small);
}

.gif-gallery__placeholder .ellipsis {
  font-family: "rocgrotesk";
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.16em;
  color: var(--red);
}

@media only screen and (max-width: 700px) {
  .gif-gallery__gif-container {
    width: 131.25px;
    margin-bottom: 10px;
  }
}
</style>
