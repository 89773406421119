<template>
  <component :category="currentRound.getCategory()" :count-down-timer="countdown" :did-submit="didSubmit" :is="activeMode"
    :hot-tip="currentRound.getHottip()" :num-rounds="numRounds" :judge-end-time="judgeEndTime" :judge-id="judgeId"
    :last-round-winner="lastRoundWinner" :player-id="playerId" :playerAvatars="playerAvatars" :players="players"
    :responses="currentRound.getChoicesList()" :round-end-time="roundEndTime" :round-number="roundNumber"
    :round-type="roundType" :scores="scores" :submissions="submissions" :submit="doSubmit"
    :submit-end-time="submitEndTime" :top-text="currentRound.getPrompt()" :winner-id="winnerId"
    :winner-sticker="winnerSticker" />
</template>

<script>
import PreGame from './pregame'
import Gallery from "./gallery";
import Question from "./Question";
import { RoundType } from "../../proto/game_pb";
import FinalGallery from "./FinalGallery";
import { audioPlayer } from "/src/soundfx/audioPlayer.js";

export default {
  name: "Round",
  mixins: [audioPlayer],
  components: {
    FinalGallery,
    Gallery,
    PreGame,
    Question,
  },
  emits: ['submit'],
  props: {
    currentRound: Object,
    didSubmit: Boolean,
    lastRoundWinner: Number,
    playerId: Number,
    players: Array,
    playerAvatars: Object,
    submissions: Object,
    winnerId: Number,
    winnerSticker: String,
    scores: Object,
    numRounds: Number
  },
  data: () => ({
    tick: null,
    countdown: null,
  }),
  computed: {
    activeMode() {
      if (this.countdown) {
        return PreGame;
      } else if (this.winnerId) {
        return FinalGallery;
      } else if (this.submitted) {
        return Gallery;
      } else {
        return Question;
      }
    },
    submitEndTime() {
      if (!this.currentRound || !this.currentRound.getRoundStart()) {
        return null;
      }
      if (!this.currentRound.getRoundEnd()) {
        return this.currentRound.getRoundStart().toDate().getTime() + 68000;
      }
      return this.currentRound.getRoundEnd().toDate().getTime();
    },
    judgeEndTime() {
      if (!this.currentRound) {
        return null;
      }
      if (!this.currentRound.getJudgeStart()) {
        if (this.currentRound.getRoundStart()) {
          return this.currentRound.getRoundStart().toDate().getTime() + 90000;
        }
        return null;
      }
      return this.currentRound.getJudgeStart().toDate().getTime() + 30000;
    },
    roundEndTime() {
      if (!this.currentRound || !this.currentRound.getRoundEnd()) {
        return null;
      }
      return this.currentRound.getRoundEnd().toDate().getTime();
    },
    roundType() {
      return this.currentRound.getRoundType() == RoundType.GIF ? "gif" : "text";
    },
    judgeId() {
      return this.currentRound.getJudgeSlot();
    },
    start() {
      return this.currentRound.getRoundStart().toDate();
    },
    submitted() {
      return (
        this.currentRound.getSubmissionsMap() &&
        this.currentRound.getSubmissionsMap().get(this.playerId)
      );
    },
    roundNumber() {
      return this.currentRound.getRoundNumber() + 1;
    },
  },
  methods: {
    doSubmit(submission) {
      submission.setRoundNumber(this.currentRound.getRoundNumber());
      this.$emit('submit', submission);
    },
    initCountdown(endAt) {
      if (this.tick) {
        clearInterval(this.tick);
      }
      if (!endAt) {
        return;
      }
      const now = new Date();
      if (endAt > now) {
        this.countdown = Math.floor((endAt - now) / 1000);
        this.tick = setInterval(() => {
          const now = new Date();
          if (endAt > now) {
            this.countdown = Math.floor((endAt - now) / 1000);
          } else {
            clearInterval(this.tick);
            this.countdown = null;
          }
        }, 500);
      }
    },
  },
  watch: {
    currentRound(round) {
      const val = round.getRoundStart().toDate();
      this.initCountdown(val);
    },
  },
  mounted: function () {
    if (this.currentRound && this.currentRound.getRoundStart()) {

      // start bg music after initial countdown SFX
      setTimeout(() => {
        this.playBackgroundMusic(this.soundConsts.BG_MUSIC_GAMEPLAY);
      }, 4000);

      this.playSound(this.soundConsts.THREE_SEC_COUNTDOWN);
      this.initCountdown(this.currentRound.getRoundStart().toDate());
    }
  },
  beforeUnmount() {
    this.stopBackgroundMusic();
  },
  unmounted: function () {
    if (this.tick) {
      clearInterval(this.tick);
    }
  },
};
</script>

<style lang="scss"></style>
