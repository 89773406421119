<template>
    <div class="language-switcher popup-control">
        <h3 @click="toggleDropdown">{{ languageTitle }}</h3>
        <div v-if="isOpen" class="language-modal">
            <button type="button" @click="switchLanguage('en')" :class="{ 'on': currentLocale === 'en' }">{{ "English"
            }}</button>
            <button type="button" @click="switchLanguage('fr-CA')" :class="{ 'on': currentLocale === 'fr-CA' }">{{
                "French" }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "LanguageSwitcher",
    data() {
        return {
            isOpen: false,
            currentLocale: this.$i18n.locale, // Track the current selected language
        };
    },
    computed: {
        languageTitle() {
            return this.currentLocale === 'en' ? 'English' : 'French';
        }
    },
    methods: {
        switchLanguage(locale) {
            this.$i18n.locale = locale;
            this.currentLocale = locale; // Update the current selected language
            this.isOpen = false; // Optionally close the modal/dropdown after selection
        },
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
    },
};
</script>
<style lang="scss">
.language-switcher {
    position: relative;
    margin-left: 5px;

    h3 {
        cursor: pointer;
        font-family: 'rocgrotesk', sans-serif;
        font-weight: 800;
        font-size: 16px;
        color: var(--navy);
        background-color: var(--white);
        border: 3px solid var(--navy);
        border-radius: 13px;
        padding: 6px 12px;
        padding-right: 40px;
        /* Increase padding to accommodate the icon */
        display: inline-block;
        margin: 0;
        text-align: center;
        position: relative;
        /* Needed for the absolute positioning of the pseudo-element */

        &::after {
            content: '';
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 12px;
            background-image: url('/assets/ui_elements/chevronDown.svg');
            background-size: cover;
            /* Ensure the icon covers the area without being distorted */
            filter: var(--filter-navy);
        }
    }
}

.language-modal {
    z-index: 8;
    position: absolute;
    right: 0;
    top: 100%;
    background-color: var(--white);
    border: 3px solid var(--navy);
    border-radius: 13px;
    width: auto;
    padding: 12px;
    display: flex;
    flex-direction: column;
    margin-top: 8px;

    button {
        font-family: 'rocgrotesk';
        font-size: 16px;
        color: var(--navy);
        text-align: left;
        padding: 6px;
        margin-bottom: 8px;
        width: 100%;
        border-radius: 8px;
        cursor: pointer;
        background-color: transparent; // Default state

        &:last-child {
            margin-bottom: 0;
        }

        &.on {
            background-color: var(--forest-100); // Selected state
            padding-right: 40px;

            &:after {
                content: url('/assets/ui_elements/check.svg'); // Indicate selected language
                position: absolute;
                right: 20px;
                filter: var(--filter-navy);
            }
        }
    }
}
</style>
