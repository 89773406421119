<template>
  <div class="gif-attr">{{ $t('powered-by-giphy') }}</div>
  <div id="gif-choice__search-bar" :class="showResults ? 'show-results' : ''">
    <input type="text" ref="gifsearchterm" id="gif-choice__input" @keyup="SearchGifs()"
      :placeholder="$t('search-for-a-gif')" />

    <button id="gif-choice__search-button" @click="doSubmit()" @mousedown="playSound(soundConsts.GENERIC_BUTTON)"
      :disabled="selectedGif === undefined ? '' : disabled" :data-ga-gif="gifText">{{ $t('submit') }}</button>
  </div>

  <div id="gif-choice__search-results" class="gif-grid__outer" :class="showResults ? 'show-results' : ''">
    <transition name="fade-gifs" v-show="showResults && paddingClass">
      <div class="gif-grid" :class="paddingClass">
        <div class="gif-grid__inner" :class="noGifs && 'no-gifs'">
          <div v-if="loading" class="gif-loading">Searching...</div>
          <div v-if="noGifs">{{ $t('no-gifs-found-try-searching-for-something-else') }}</div>
          <div class="gif-choice__gif" v-for="url in gifs" v-bind:key="url" :class="selectedGif == url && 'selected'">
            <img @click="ChooseGif(url)" @mousedown="playSound(soundConsts.SELECT_RESPONSE_BUTTON)" :src="url" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { Submission } from "../../../proto/game_pb";
import { audioPlayer } from "/src/soundfx/audioPlayer.js";

export default {
  name: "GifChoice",
  mixins: [audioPlayer],
  props: {
    category: String,
    players: Array,
    responses: Array,
    roundNumber: Number,
    numRounds: Number,
    submit: Function,
    topText: String,
  },
  data() {
    return {
      paddingClass: "",
      gifs: undefined,
      loading: false,
      selectedGif: undefined,
      gifText: "",
      showResults: false,
    };
  },
  computed: {
    noGifs() {
      return this.gifs && this.gifs.length < 1;
    },
  },
  methods: {
    SearchGifs() {
      if (!this.showResults) { this.showResults = true }
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {

        const searchTerm = this.$refs.gifsearchterm.value;
        this.loadingGifs = true;
        fetch(`/api/v1/gif?s=${encodeURIComponent(searchTerm)}`)
          .then((x) => x.json())
          .then((x) => {
            this.gifs = x || [];
            this.loading = false;
            this.paddingClass = "add-padding";
            this.gifText = this.$refs.gifsearchterm.value;
          });
      }, 500);
    },
    ChooseGif(url) {
      this.selectedGif = url;
    },
    doSubmit() {
      const submission = new Submission();
      submission.setGifUrl(this.selectedGif);
      this.submit(submission);

      // Google Tag Manager Event
      this.$gtm.trackEvent({
        event: 'gif_response_submit',
        number_players: this.players.length,
        response_submitted: this.gifText,
        round_number: this.roundNumber,
        round_name: this.roundNumber === this.numRounds ? 'final round' : this.roundNumber,
        scenario_category: this.category,
        scenario_text: this.topText,
        timed_out_response: 'no',
      });
    },
  },
  watch: {
    responses: function (newVal) {
      this.paddingClass = "add-padding";

      if (newVal.length >= 1) {
        this.noGifsReturned = false;
        this.paddingClass = "add-padding";
      } else {
        this.noGifsReturned = true;
        this.paddingClass = "add-padding no-results";
      }
    },
  },
};
</script>

<style lang="scss">
.Choice {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  #gif-choice__search-bar {
    flex: none;
    max-width: 790px;
  }

  #gif-choice__search-results {
    flex: auto;

    &.show-results {
      .gif-grid {
        border-radius: 0;
      }
    }
  }

  #gif-choice__search-button {
    flex: none;
  }
}

.play {
  height: 100vh;
}

.gif-grid {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--white);
  box-shadow: 0 0 0 3px var(--navy);
  border-radius: 0 0 3px 3px;
  z-index: 0;
  overflow-y: auto;

  &.add-padding {
    padding: 1em;
    column-gap: 1em;

    &.no-results {
      column-count: initial;
    }
  }

  &__outer {
    position: relative;
    width: 100%;
    max-width: 790px;
    /* minus border */
    margin: 0 auto 20px;
  }

  &__inner {
    width: 100%;
    height: auto;
    column-count: 2;

    @media only screen and (min-width: 450px) {
      column-count: 3;
    }

    @media only screen and (min-width: 768px) {
      column-count: 4;
    }

    &.no-gifs {
      column-count: initial;
    }
  }
}

.gif-choice__gif {
  width: 100%;
  margin-bottom: 1em;

  &.selected {
    position: relative;

    &::before {
      content: url('/assets/ui_elements/check.svg');
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      top: 50%;
      left: 50%;
      border-radius: 15px;
      background-color: var(--navy);
      transform: translate(-50%, -50%);
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: 0 0 0 3px var(--navy) inset;
      background-color: rgba(44, 37, 82, 0.2);
    }
  }

  img {
    display: block;
    width: 100%;
    cursor: pointer;
  }
}

#gif-choice {
  background-color: var(--light-forest);
  padding-bottom: 50px;

  p {
    margin-bottom: 100px;

    @media only screen and (max-width: 700px) {
      margin-bottom: 35px;
    }
  }
}

#gif-choice__search-bar {
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 3px var(--navy);
  border-radius: var(--radius-xsmall);
  background: var(--white);
  z-index: 1;

  &.show-results {
    border-radius: 0;

    #gif-choice__input {
      border-radius: 0;
    }
  }
}

#gif-choice__input {
  width: 100%;
  height: 60px;
  border: 0;
  border-radius: var(--radius-xsmall);
  padding-left: 15px;
  font-family: "rocgrotesk";
  font-size: 16px;
}

#gif-choice__search-button {
  width: 100%;
  max-width: 134px;
  margin: 0 auto;
  height: 60px;
  background-color: var(--navy);
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: var(--white);
  border: none;
  cursor: pointer;

  @media only screen and (max-width: 500px) {
    max-width: 110px;
    font-size: 18px;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: no-drop;

    &:hover {
      opacity: 0.5;
    }
  }
}

.gif-attr {
  font-size: 11px;
  text-align: right;
  margin-top: calc(var(--spacing-small) * -1);
  margin-bottom: var(--spacing-small);
  opacity: 0.5;
}

#gif-choice__search-button:hover {
  opacity: 0.9;
}

.gif-loading {
  padding: 15px;
  font-size: 20px;
}

.fade-gifs-enter-active,
.fade-gifs-leave-active {
  transition: opacity 0.5s ease;
}

.fade-gifs-enter-from,
.fade-gifs-leave-to {
  opacity: 0;
}
</style>
