// source: game.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.likelystory.GameData', null, global);
goog.exportSymbol('proto.likelystory.Player', null, global);
goog.exportSymbol('proto.likelystory.PlayerChoiceSet', null, global);
goog.exportSymbol('proto.likelystory.PlayerContext', null, global);
goog.exportSymbol('proto.likelystory.PlayerState', null, global);
goog.exportSymbol('proto.likelystory.RoomState', null, global);
goog.exportSymbol('proto.likelystory.RoundContext', null, global);
goog.exportSymbol('proto.likelystory.RoundData', null, global);
goog.exportSymbol('proto.likelystory.RoundState', null, global);
goog.exportSymbol('proto.likelystory.RoundType', null, global);
goog.exportSymbol('proto.likelystory.Submission', null, global);
goog.exportSymbol('proto.likelystory.Submission.AnswerCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.likelystory.RoomState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.likelystory.RoomState.repeatedFields_, null);
};
goog.inherits(proto.likelystory.RoomState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.likelystory.RoomState.displayName = 'proto.likelystory.RoomState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.likelystory.RoundState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.likelystory.RoundState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.likelystory.RoundState.displayName = 'proto.likelystory.RoundState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.likelystory.PlayerState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.likelystory.PlayerState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.likelystory.PlayerState.displayName = 'proto.likelystory.PlayerState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.likelystory.GameData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.likelystory.GameData.repeatedFields_, null);
};
goog.inherits(proto.likelystory.GameData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.likelystory.GameData.displayName = 'proto.likelystory.GameData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.likelystory.RoundData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.likelystory.RoundData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.likelystory.RoundData.displayName = 'proto.likelystory.RoundData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.likelystory.PlayerChoiceSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.likelystory.PlayerChoiceSet.repeatedFields_, null);
};
goog.inherits(proto.likelystory.PlayerChoiceSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.likelystory.PlayerChoiceSet.displayName = 'proto.likelystory.PlayerChoiceSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.likelystory.Player = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.likelystory.Player, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.likelystory.Player.displayName = 'proto.likelystory.Player';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.likelystory.Submission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.likelystory.Submission.oneofGroups_);
};
goog.inherits(proto.likelystory.Submission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.likelystory.Submission.displayName = 'proto.likelystory.Submission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.likelystory.PlayerContext = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.likelystory.PlayerContext, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.likelystory.PlayerContext.displayName = 'proto.likelystory.PlayerContext';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.likelystory.RoundContext = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.likelystory.RoundContext.repeatedFields_, null);
};
goog.inherits(proto.likelystory.RoundContext, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.likelystory.RoundContext.displayName = 'proto.likelystory.RoundContext';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.likelystory.RoomState.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.likelystory.RoomState.prototype.toObject = function(opt_includeInstance) {
  return proto.likelystory.RoomState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.likelystory.RoomState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.RoomState.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roundNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    playersList: jspb.Message.toObjectList(msg.getPlayersList(),
    proto.likelystory.Player.toObject, includeInstance),
    roundwinnersMap: (f = msg.getRoundwinnersMap()) ? f.toObject(includeInstance, undefined) : [],
    lastActiveAt: (f = msg.getLastActiveAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.likelystory.RoomState}
 */
proto.likelystory.RoomState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.likelystory.RoomState;
  return proto.likelystory.RoomState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.likelystory.RoomState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.likelystory.RoomState}
 */
proto.likelystory.RoomState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRoundNumber(value);
      break;
    case 3:
      var value = new proto.likelystory.Player;
      reader.readMessage(value,proto.likelystory.Player.deserializeBinaryFromReader);
      msg.addPlayers(value);
      break;
    case 4:
      var value = msg.getRoundwinnersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readInt32, null, 0, 0);
         });
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastActiveAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.likelystory.RoomState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.likelystory.RoomState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.likelystory.RoomState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.RoomState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoundNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.likelystory.Player.serializeBinaryToWriter
    );
  }
  f = message.getRoundwinnersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeInt32);
  }
  f = message.getLastActiveAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.likelystory.RoomState.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.likelystory.RoomState} returns this
 */
proto.likelystory.RoomState.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 round_number = 2;
 * @return {number}
 */
proto.likelystory.RoomState.prototype.getRoundNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.likelystory.RoomState} returns this
 */
proto.likelystory.RoomState.prototype.setRoundNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated Player players = 3;
 * @return {!Array<!proto.likelystory.Player>}
 */
proto.likelystory.RoomState.prototype.getPlayersList = function() {
  return /** @type{!Array<!proto.likelystory.Player>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.likelystory.Player, 3));
};


/**
 * @param {!Array<!proto.likelystory.Player>} value
 * @return {!proto.likelystory.RoomState} returns this
*/
proto.likelystory.RoomState.prototype.setPlayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.likelystory.Player=} opt_value
 * @param {number=} opt_index
 * @return {!proto.likelystory.Player}
 */
proto.likelystory.RoomState.prototype.addPlayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.likelystory.Player, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.likelystory.RoomState} returns this
 */
proto.likelystory.RoomState.prototype.clearPlayersList = function() {
  return this.setPlayersList([]);
};


/**
 * map<int32, int32> roundWinners = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.likelystory.RoomState.prototype.getRoundwinnersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.likelystory.RoomState} returns this
 */
proto.likelystory.RoomState.prototype.clearRoundwinnersMap = function() {
  this.getRoundwinnersMap().clear();
  return this;};


/**
 * optional google.protobuf.Timestamp last_active_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.likelystory.RoomState.prototype.getLastActiveAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.likelystory.RoomState} returns this
*/
proto.likelystory.RoomState.prototype.setLastActiveAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.likelystory.RoomState} returns this
 */
proto.likelystory.RoomState.prototype.clearLastActiveAt = function() {
  return this.setLastActiveAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.likelystory.RoomState.prototype.hasLastActiveAt = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.likelystory.RoundState.prototype.toObject = function(opt_includeInstance) {
  return proto.likelystory.RoundState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.likelystory.RoundState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.RoundState.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    judgeTime: (f = msg.getJudgeTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    submissionsMap: (f = msg.getSubmissionsMap()) ? f.toObject(includeInstance, proto.likelystory.Submission.toObject) : [],
    judgeId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.likelystory.RoundState}
 */
proto.likelystory.RoundState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.likelystory.RoundState;
  return proto.likelystory.RoundState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.likelystory.RoundState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.likelystory.RoundState}
 */
proto.likelystory.RoundState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setJudgeTime(value);
      break;
    case 4:
      var value = msg.getSubmissionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.likelystory.Submission.deserializeBinaryFromReader, 0, new proto.likelystory.Submission());
         });
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setJudgeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.likelystory.RoundState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.likelystory.RoundState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.likelystory.RoundState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.RoundState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getJudgeTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSubmissionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.likelystory.Submission.serializeBinaryToWriter);
  }
  f = message.getJudgeId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp start_time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.likelystory.RoundState.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.likelystory.RoundState} returns this
*/
proto.likelystory.RoundState.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.likelystory.RoundState} returns this
 */
proto.likelystory.RoundState.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.likelystory.RoundState.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.likelystory.RoundState.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.likelystory.RoundState} returns this
*/
proto.likelystory.RoundState.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.likelystory.RoundState} returns this
 */
proto.likelystory.RoundState.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.likelystory.RoundState.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp judge_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.likelystory.RoundState.prototype.getJudgeTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.likelystory.RoundState} returns this
*/
proto.likelystory.RoundState.prototype.setJudgeTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.likelystory.RoundState} returns this
 */
proto.likelystory.RoundState.prototype.clearJudgeTime = function() {
  return this.setJudgeTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.likelystory.RoundState.prototype.hasJudgeTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * map<int32, Submission> submissions = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.likelystory.Submission>}
 */
proto.likelystory.RoundState.prototype.getSubmissionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.likelystory.Submission>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.likelystory.Submission));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.likelystory.RoundState} returns this
 */
proto.likelystory.RoundState.prototype.clearSubmissionsMap = function() {
  this.getSubmissionsMap().clear();
  return this;};


/**
 * optional string judge_id = 5;
 * @return {string}
 */
proto.likelystory.RoundState.prototype.getJudgeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.likelystory.RoundState} returns this
 */
proto.likelystory.RoundState.prototype.setJudgeId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.likelystory.PlayerState.prototype.toObject = function(opt_includeInstance) {
  return proto.likelystory.PlayerState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.likelystory.PlayerState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.PlayerState.toObject = function(includeInstance, msg) {
  var f, obj = {
    connected: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    currentSubmission: (f = msg.getCurrentSubmission()) && proto.likelystory.Submission.toObject(includeInstance, f),
    lastActiveAt: (f = msg.getLastActiveAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.likelystory.PlayerState}
 */
proto.likelystory.PlayerState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.likelystory.PlayerState;
  return proto.likelystory.PlayerState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.likelystory.PlayerState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.likelystory.PlayerState}
 */
proto.likelystory.PlayerState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConnected(value);
      break;
    case 2:
      var value = new proto.likelystory.Submission;
      reader.readMessage(value,proto.likelystory.Submission.deserializeBinaryFromReader);
      msg.setCurrentSubmission(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastActiveAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.likelystory.PlayerState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.likelystory.PlayerState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.likelystory.PlayerState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.PlayerState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConnected();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getCurrentSubmission();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.likelystory.Submission.serializeBinaryToWriter
    );
  }
  f = message.getLastActiveAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool connected = 1;
 * @return {boolean}
 */
proto.likelystory.PlayerState.prototype.getConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.likelystory.PlayerState} returns this
 */
proto.likelystory.PlayerState.prototype.setConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Submission current_submission = 2;
 * @return {?proto.likelystory.Submission}
 */
proto.likelystory.PlayerState.prototype.getCurrentSubmission = function() {
  return /** @type{?proto.likelystory.Submission} */ (
    jspb.Message.getWrapperField(this, proto.likelystory.Submission, 2));
};


/**
 * @param {?proto.likelystory.Submission|undefined} value
 * @return {!proto.likelystory.PlayerState} returns this
*/
proto.likelystory.PlayerState.prototype.setCurrentSubmission = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.likelystory.PlayerState} returns this
 */
proto.likelystory.PlayerState.prototype.clearCurrentSubmission = function() {
  return this.setCurrentSubmission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.likelystory.PlayerState.prototype.hasCurrentSubmission = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp last_active_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.likelystory.PlayerState.prototype.getLastActiveAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.likelystory.PlayerState} returns this
*/
proto.likelystory.PlayerState.prototype.setLastActiveAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.likelystory.PlayerState} returns this
 */
proto.likelystory.PlayerState.prototype.clearLastActiveAt = function() {
  return this.setLastActiveAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.likelystory.PlayerState.prototype.hasLastActiveAt = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.likelystory.GameData.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.likelystory.GameData.prototype.toObject = function(opt_includeInstance) {
  return proto.likelystory.GameData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.likelystory.GameData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.GameData.toObject = function(includeInstance, msg) {
  var f, obj = {
    roundsList: jspb.Message.toObjectList(msg.getRoundsList(),
    proto.likelystory.RoundData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.likelystory.GameData}
 */
proto.likelystory.GameData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.likelystory.GameData;
  return proto.likelystory.GameData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.likelystory.GameData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.likelystory.GameData}
 */
proto.likelystory.GameData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.likelystory.RoundData;
      reader.readMessage(value,proto.likelystory.RoundData.deserializeBinaryFromReader);
      msg.addRounds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.likelystory.GameData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.likelystory.GameData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.likelystory.GameData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.GameData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoundsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.likelystory.RoundData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RoundData rounds = 1;
 * @return {!Array<!proto.likelystory.RoundData>}
 */
proto.likelystory.GameData.prototype.getRoundsList = function() {
  return /** @type{!Array<!proto.likelystory.RoundData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.likelystory.RoundData, 1));
};


/**
 * @param {!Array<!proto.likelystory.RoundData>} value
 * @return {!proto.likelystory.GameData} returns this
*/
proto.likelystory.GameData.prototype.setRoundsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.likelystory.RoundData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.likelystory.RoundData}
 */
proto.likelystory.GameData.prototype.addRounds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.likelystory.RoundData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.likelystory.GameData} returns this
 */
proto.likelystory.GameData.prototype.clearRoundsList = function() {
  return this.setRoundsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.likelystory.RoundData.prototype.toObject = function(opt_includeInstance) {
  return proto.likelystory.RoundData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.likelystory.RoundData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.RoundData.toObject = function(includeInstance, msg) {
  var f, obj = {
    prompt: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playerChoiceSetsMap: (f = msg.getPlayerChoiceSetsMap()) ? f.toObject(includeInstance, proto.likelystory.PlayerChoiceSet.toObject) : [],
    roundType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    hottip: jspb.Message.getFieldWithDefault(msg, 4, ""),
    category: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.likelystory.RoundData}
 */
proto.likelystory.RoundData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.likelystory.RoundData;
  return proto.likelystory.RoundData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.likelystory.RoundData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.likelystory.RoundData}
 */
proto.likelystory.RoundData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrompt(value);
      break;
    case 2:
      var value = msg.getPlayerChoiceSetsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.likelystory.PlayerChoiceSet.deserializeBinaryFromReader, 0, new proto.likelystory.PlayerChoiceSet());
         });
      break;
    case 3:
      var value = /** @type {!proto.likelystory.RoundType} */ (reader.readEnum());
      msg.setRoundType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHottip(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.likelystory.RoundData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.likelystory.RoundData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.likelystory.RoundData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.RoundData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrompt();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayerChoiceSetsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.likelystory.PlayerChoiceSet.serializeBinaryToWriter);
  }
  f = message.getRoundType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getHottip();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string prompt = 1;
 * @return {string}
 */
proto.likelystory.RoundData.prototype.getPrompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.likelystory.RoundData} returns this
 */
proto.likelystory.RoundData.prototype.setPrompt = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<int32, PlayerChoiceSet> player_choice_sets = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.likelystory.PlayerChoiceSet>}
 */
proto.likelystory.RoundData.prototype.getPlayerChoiceSetsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.likelystory.PlayerChoiceSet>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.likelystory.PlayerChoiceSet));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.likelystory.RoundData} returns this
 */
proto.likelystory.RoundData.prototype.clearPlayerChoiceSetsMap = function() {
  this.getPlayerChoiceSetsMap().clear();
  return this;};


/**
 * optional RoundType round_type = 3;
 * @return {!proto.likelystory.RoundType}
 */
proto.likelystory.RoundData.prototype.getRoundType = function() {
  return /** @type {!proto.likelystory.RoundType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.likelystory.RoundType} value
 * @return {!proto.likelystory.RoundData} returns this
 */
proto.likelystory.RoundData.prototype.setRoundType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string hottip = 4;
 * @return {string}
 */
proto.likelystory.RoundData.prototype.getHottip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.likelystory.RoundData} returns this
 */
proto.likelystory.RoundData.prototype.setHottip = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string category = 5;
 * @return {string}
 */
proto.likelystory.RoundData.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.likelystory.RoundData} returns this
 */
proto.likelystory.RoundData.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.likelystory.PlayerChoiceSet.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.likelystory.PlayerChoiceSet.prototype.toObject = function(opt_includeInstance) {
  return proto.likelystory.PlayerChoiceSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.likelystory.PlayerChoiceSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.PlayerChoiceSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    choicesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.likelystory.PlayerChoiceSet}
 */
proto.likelystory.PlayerChoiceSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.likelystory.PlayerChoiceSet;
  return proto.likelystory.PlayerChoiceSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.likelystory.PlayerChoiceSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.likelystory.PlayerChoiceSet}
 */
proto.likelystory.PlayerChoiceSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addChoices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.likelystory.PlayerChoiceSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.likelystory.PlayerChoiceSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.likelystory.PlayerChoiceSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.PlayerChoiceSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChoicesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string choices = 1;
 * @return {!Array<string>}
 */
proto.likelystory.PlayerChoiceSet.prototype.getChoicesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.likelystory.PlayerChoiceSet} returns this
 */
proto.likelystory.PlayerChoiceSet.prototype.setChoicesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.likelystory.PlayerChoiceSet} returns this
 */
proto.likelystory.PlayerChoiceSet.prototype.addChoices = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.likelystory.PlayerChoiceSet} returns this
 */
proto.likelystory.PlayerChoiceSet.prototype.clearChoicesList = function() {
  return this.setChoicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.likelystory.Player.prototype.toObject = function(opt_includeInstance) {
  return proto.likelystory.Player.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.likelystory.Player} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.Player.toObject = function(includeInstance, msg) {
  var f, obj = {
    slot: jspb.Message.getFieldWithDefault(msg, 1, 0),
    avatar: jspb.Message.getFieldWithDefault(msg, 2, 0),
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.likelystory.Player}
 */
proto.likelystory.Player.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.likelystory.Player;
  return proto.likelystory.Player.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.likelystory.Player} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.likelystory.Player}
 */
proto.likelystory.Player.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSlot(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvatar(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.likelystory.Player.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.likelystory.Player.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.likelystory.Player} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.Player.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlot();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAvatar();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 slot = 1;
 * @return {number}
 */
proto.likelystory.Player.prototype.getSlot = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.likelystory.Player} returns this
 */
proto.likelystory.Player.prototype.setSlot = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 avatar = 2;
 * @return {number}
 */
proto.likelystory.Player.prototype.getAvatar = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.likelystory.Player} returns this
 */
proto.likelystory.Player.prototype.setAvatar = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.likelystory.Player.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.likelystory.Player} returns this
 */
proto.likelystory.Player.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.likelystory.Submission.oneofGroups_ = [[2,3,4]];

/**
 * @enum {number}
 */
proto.likelystory.Submission.AnswerCase = {
  ANSWER_NOT_SET: 0,
  TEXT: 2,
  GIF_URL: 3,
  JUDGEMENT: 4
};

/**
 * @return {proto.likelystory.Submission.AnswerCase}
 */
proto.likelystory.Submission.prototype.getAnswerCase = function() {
  return /** @type {proto.likelystory.Submission.AnswerCase} */(jspb.Message.computeOneofCase(this, proto.likelystory.Submission.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.likelystory.Submission.prototype.toObject = function(opt_includeInstance) {
  return proto.likelystory.Submission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.likelystory.Submission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.Submission.toObject = function(includeInstance, msg) {
  var f, obj = {
    roundNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    gifUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    judgement: jspb.Message.getFieldWithDefault(msg, 4, 0),
    playerId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.likelystory.Submission}
 */
proto.likelystory.Submission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.likelystory.Submission;
  return proto.likelystory.Submission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.likelystory.Submission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.likelystory.Submission}
 */
proto.likelystory.Submission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRoundNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGifUrl(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJudgement(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.likelystory.Submission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.likelystory.Submission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.likelystory.Submission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.Submission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoundNumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 round_number = 1;
 * @return {number}
 */
proto.likelystory.Submission.prototype.getRoundNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.likelystory.Submission} returns this
 */
proto.likelystory.Submission.prototype.setRoundNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.likelystory.Submission.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.likelystory.Submission} returns this
 */
proto.likelystory.Submission.prototype.setText = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.likelystory.Submission.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.likelystory.Submission} returns this
 */
proto.likelystory.Submission.prototype.clearText = function() {
  return jspb.Message.setOneofField(this, 2, proto.likelystory.Submission.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.likelystory.Submission.prototype.hasText = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string gif_url = 3;
 * @return {string}
 */
proto.likelystory.Submission.prototype.getGifUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.likelystory.Submission} returns this
 */
proto.likelystory.Submission.prototype.setGifUrl = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.likelystory.Submission.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.likelystory.Submission} returns this
 */
proto.likelystory.Submission.prototype.clearGifUrl = function() {
  return jspb.Message.setOneofField(this, 3, proto.likelystory.Submission.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.likelystory.Submission.prototype.hasGifUrl = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 judgement = 4;
 * @return {number}
 */
proto.likelystory.Submission.prototype.getJudgement = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.likelystory.Submission} returns this
 */
proto.likelystory.Submission.prototype.setJudgement = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.likelystory.Submission.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.likelystory.Submission} returns this
 */
proto.likelystory.Submission.prototype.clearJudgement = function() {
  return jspb.Message.setOneofField(this, 4, proto.likelystory.Submission.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.likelystory.Submission.prototype.hasJudgement = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string player_id = 5;
 * @return {string}
 */
proto.likelystory.Submission.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.likelystory.Submission} returns this
 */
proto.likelystory.Submission.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.likelystory.PlayerContext.prototype.toObject = function(opt_includeInstance) {
  return proto.likelystory.PlayerContext.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.likelystory.PlayerContext} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.PlayerContext.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playersMap: (f = msg.getPlayersMap()) ? f.toObject(includeInstance, undefined) : [],
    slot: jspb.Message.getFieldWithDefault(msg, 3, 0),
    roundWinnersMap: (f = msg.getRoundWinnersMap()) ? f.toObject(includeInstance, undefined) : [],
    currentRound: (f = msg.getCurrentRound()) && proto.likelystory.RoundContext.toObject(includeInstance, f),
    numRounds: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.likelystory.PlayerContext}
 */
proto.likelystory.PlayerContext.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.likelystory.PlayerContext;
  return proto.likelystory.PlayerContext.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.likelystory.PlayerContext} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.likelystory.PlayerContext}
 */
proto.likelystory.PlayerContext.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = msg.getPlayersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readInt32, null, 0, 0);
         });
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSlot(value);
      break;
    case 4:
      var value = msg.getRoundWinnersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readInt32, null, 0, 0);
         });
      break;
    case 5:
      var value = new proto.likelystory.RoundContext;
      reader.readMessage(value,proto.likelystory.RoundContext.deserializeBinaryFromReader);
      msg.setCurrentRound(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumRounds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.likelystory.PlayerContext.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.likelystory.PlayerContext.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.likelystory.PlayerContext} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.PlayerContext.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeInt32);
  }
  f = message.getSlot();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRoundWinnersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeInt32);
  }
  f = message.getCurrentRound();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.likelystory.RoundContext.serializeBinaryToWriter
    );
  }
  f = message.getNumRounds();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.likelystory.PlayerContext.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.likelystory.PlayerContext} returns this
 */
proto.likelystory.PlayerContext.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<int32, int32> players = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.likelystory.PlayerContext.prototype.getPlayersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.likelystory.PlayerContext} returns this
 */
proto.likelystory.PlayerContext.prototype.clearPlayersMap = function() {
  this.getPlayersMap().clear();
  return this;};


/**
 * optional int32 slot = 3;
 * @return {number}
 */
proto.likelystory.PlayerContext.prototype.getSlot = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.likelystory.PlayerContext} returns this
 */
proto.likelystory.PlayerContext.prototype.setSlot = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * map<int32, int32> round_winners = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.likelystory.PlayerContext.prototype.getRoundWinnersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.likelystory.PlayerContext} returns this
 */
proto.likelystory.PlayerContext.prototype.clearRoundWinnersMap = function() {
  this.getRoundWinnersMap().clear();
  return this;};


/**
 * optional RoundContext current_round = 5;
 * @return {?proto.likelystory.RoundContext}
 */
proto.likelystory.PlayerContext.prototype.getCurrentRound = function() {
  return /** @type{?proto.likelystory.RoundContext} */ (
    jspb.Message.getWrapperField(this, proto.likelystory.RoundContext, 5));
};


/**
 * @param {?proto.likelystory.RoundContext|undefined} value
 * @return {!proto.likelystory.PlayerContext} returns this
*/
proto.likelystory.PlayerContext.prototype.setCurrentRound = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.likelystory.PlayerContext} returns this
 */
proto.likelystory.PlayerContext.prototype.clearCurrentRound = function() {
  return this.setCurrentRound(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.likelystory.PlayerContext.prototype.hasCurrentRound = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 num_rounds = 6;
 * @return {number}
 */
proto.likelystory.PlayerContext.prototype.getNumRounds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.likelystory.PlayerContext} returns this
 */
proto.likelystory.PlayerContext.prototype.setNumRounds = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.likelystory.RoundContext.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.likelystory.RoundContext.prototype.toObject = function(opt_includeInstance) {
  return proto.likelystory.RoundContext.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.likelystory.RoundContext} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.RoundContext.toObject = function(includeInstance, msg) {
  var f, obj = {
    roundNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    roundType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    submissionsMap: (f = msg.getSubmissionsMap()) ? f.toObject(includeInstance, undefined) : [],
    choicesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    judgeSlot: jspb.Message.getFieldWithDefault(msg, 5, 0),
    prompt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    roundStart: (f = msg.getRoundStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    roundEnd: (f = msg.getRoundEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    judgeStart: (f = msg.getJudgeStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    hottip: jspb.Message.getFieldWithDefault(msg, 10, ""),
    category: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.likelystory.RoundContext}
 */
proto.likelystory.RoundContext.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.likelystory.RoundContext;
  return proto.likelystory.RoundContext.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.likelystory.RoundContext} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.likelystory.RoundContext}
 */
proto.likelystory.RoundContext.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRoundNumber(value);
      break;
    case 2:
      var value = /** @type {!proto.likelystory.RoundType} */ (reader.readEnum());
      msg.setRoundType(value);
      break;
    case 3:
      var value = msg.getSubmissionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readString, null, 0, "");
         });
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addChoices(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJudgeSlot(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrompt(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRoundStart(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRoundEnd(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setJudgeStart(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setHottip(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.likelystory.RoundContext.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.likelystory.RoundContext.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.likelystory.RoundContext} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.likelystory.RoundContext.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoundNumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRoundType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSubmissionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getChoicesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getJudgeSlot();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPrompt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRoundStart();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRoundEnd();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getJudgeStart();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getHottip();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional int32 round_number = 1;
 * @return {number}
 */
proto.likelystory.RoundContext.prototype.getRoundNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.likelystory.RoundContext} returns this
 */
proto.likelystory.RoundContext.prototype.setRoundNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional RoundType round_type = 2;
 * @return {!proto.likelystory.RoundType}
 */
proto.likelystory.RoundContext.prototype.getRoundType = function() {
  return /** @type {!proto.likelystory.RoundType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.likelystory.RoundType} value
 * @return {!proto.likelystory.RoundContext} returns this
 */
proto.likelystory.RoundContext.prototype.setRoundType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * map<int32, string> submissions = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,string>}
 */
proto.likelystory.RoundContext.prototype.getSubmissionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.likelystory.RoundContext} returns this
 */
proto.likelystory.RoundContext.prototype.clearSubmissionsMap = function() {
  this.getSubmissionsMap().clear();
  return this;};


/**
 * repeated string choices = 4;
 * @return {!Array<string>}
 */
proto.likelystory.RoundContext.prototype.getChoicesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.likelystory.RoundContext} returns this
 */
proto.likelystory.RoundContext.prototype.setChoicesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.likelystory.RoundContext} returns this
 */
proto.likelystory.RoundContext.prototype.addChoices = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.likelystory.RoundContext} returns this
 */
proto.likelystory.RoundContext.prototype.clearChoicesList = function() {
  return this.setChoicesList([]);
};


/**
 * optional int32 judge_slot = 5;
 * @return {number}
 */
proto.likelystory.RoundContext.prototype.getJudgeSlot = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.likelystory.RoundContext} returns this
 */
proto.likelystory.RoundContext.prototype.setJudgeSlot = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string prompt = 6;
 * @return {string}
 */
proto.likelystory.RoundContext.prototype.getPrompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.likelystory.RoundContext} returns this
 */
proto.likelystory.RoundContext.prototype.setPrompt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp round_start = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.likelystory.RoundContext.prototype.getRoundStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.likelystory.RoundContext} returns this
*/
proto.likelystory.RoundContext.prototype.setRoundStart = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.likelystory.RoundContext} returns this
 */
proto.likelystory.RoundContext.prototype.clearRoundStart = function() {
  return this.setRoundStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.likelystory.RoundContext.prototype.hasRoundStart = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp round_end = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.likelystory.RoundContext.prototype.getRoundEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.likelystory.RoundContext} returns this
*/
proto.likelystory.RoundContext.prototype.setRoundEnd = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.likelystory.RoundContext} returns this
 */
proto.likelystory.RoundContext.prototype.clearRoundEnd = function() {
  return this.setRoundEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.likelystory.RoundContext.prototype.hasRoundEnd = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp judge_start = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.likelystory.RoundContext.prototype.getJudgeStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.likelystory.RoundContext} returns this
*/
proto.likelystory.RoundContext.prototype.setJudgeStart = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.likelystory.RoundContext} returns this
 */
proto.likelystory.RoundContext.prototype.clearJudgeStart = function() {
  return this.setJudgeStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.likelystory.RoundContext.prototype.hasJudgeStart = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string hottip = 10;
 * @return {string}
 */
proto.likelystory.RoundContext.prototype.getHottip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.likelystory.RoundContext} returns this
 */
proto.likelystory.RoundContext.prototype.setHottip = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string category = 11;
 * @return {string}
 */
proto.likelystory.RoundContext.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.likelystory.RoundContext} returns this
 */
proto.likelystory.RoundContext.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * @enum {number}
 */
proto.likelystory.RoundType = {
  TEXT: 0,
  GIF: 1
};

goog.object.extend(exports, proto.likelystory);
