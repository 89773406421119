<template>
  <div class="play__responses">
    <div class="play__response" v-for="item in responses" v-bind:key="item">
      <button @click="doSubmit(item)" :data-ga-button="item">
        <span>{{ item }}</span>
      </button>
    </div>
    <div class="play__response play__response__custom">
      <form @submit.prevent="doSubmit(freetext, true)" autocomplete="off">
        <div class="play__response__custom__wrapper">
          <input class="play__response__other" name="otherChoice" :placeholder="$t('write-your-own-response')"
            maxlength="30" type="text" v-model="freetext" />
          <DonutProgress v-if="freetext" :num="freetext.length" :max="maxLength" size="23px" />
        </div>
        <Transition name="show" appear>
          <input v-if="freetext" type="submit" value="Submit Response" class="play__response__submit"
            :data-ga-button="freetext" />
        </Transition>
      </form>
    </div>
  </div>
</template>

<script>
import { Submission } from "../../../proto/game_pb";
import { audioPlayer } from "/src/soundfx/audioPlayer.js";
// import DonutProgress from "../../util/DonutProgress";

export default {
  name: "TextChoice",
  mixins: [audioPlayer],
  components: {
    // DonutProgress
  },
  props: {
    category: String,
    players: Array,
    responses: Array,
    roundNumber: Number,
    numRounds: Number,
    submit: Function,
    topText: String,
  },
  data: () => ({
    freetext: '',
    maxLength: 30,
  }),
  methods: {
    doSubmit(textChoice, custom = false) {
      this.playSound(this.soundConsts.SELECT_RESPONSE_BUTTON);

      const submission = new Submission();
      submission.setText(textChoice);
      this.submit(submission);

      console.log("hello");
      console.log(this.roundNumber);

      // Google Tag Manager Event
      this.$gtm.trackEvent({
        event: 'text_response_submit',
        custom_response: custom,
        number_players: this.players.length,
        response_submitted: textChoice,
        round_number: this.roundNumber,
        round_name: this.roundNumber === this.numRounds ? 'final round' : this.roundNumber,
        scenario_category: this.category,
        scenario_text: this.topText,
        timed_out_response: 'no',
      });
    },
  },
  mounted: function () {
    // Clear buttons from being focused on load.
    Array.from(document.querySelectorAll('.play__response button')).forEach(el => el.blur())
  },
};
</script>

<style lang="scss" scoped>
.play__responses {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
}

.play__response {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 18px;

  @media only screen and (max-width: 700px) {
    width: 100%;
  }

  button {
    width: 100%;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    background-color: var(--white);
    color: var(--navy);
    padding: 11px 17px;
    margin: 0;
    border-radius: 1000px;
    height: 55px;


    @media only screen and (min-width: 701px) {
      height: 60px;
    }
  }

  form {
    width: 100%;
  }


  &__custom {
    position: relative;
    width: 100%;

    &__wrapper {
      position: relative;

      svg {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__other {
    width: 100%;
    font-family: 'rocgrotesk', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: var(--navy);
    box-shadow: 0 0 0 3px var(--navy) inset;
    padding: 11px 36px !important;
    border: 0;
    border-radius: 1000px;
    height: 55px;

    @media only screen and (min-width: 701px) {
      height: 60px;
    }

    &:focus,
    &:active {
      color: var(--navy);
    }

    &:hover {
      color: var(--navy) !important;
    }

    &::placeholder {
      color: var(--navy);
      opacity: 0.5;
      text-align: center;
    }

    &:selected {
      background-color: var(--navy);
    }
  }

  &__submit {
    position: relative;
    box-shadow: none;
    border: none;
    width: 100%;
    font-family: 'rocgrotesk', sans-serif;
    font-size: 20px;
    text-align: center;
    color: var(--white);
    background-color: var(--navy);
    padding: 10px 0;
    margin: 10px 0;
    cursor: pointer;
    border-radius: 3px;
    transition: color .2s $ease-1;
    overflow: hidden;
  }
}

.show-enter-active,
.show-leave-active {
  opacity: 1;
  transform: scale(1);
  transition: transform 0.16s $ease-3;
}

.show-enter-from,
.show-leave-to {
  opacity: 0;
  transform: scale(1.5);
}
</style>
