<template>
    <div class="share-code-wrapper">
        <div class="code">
            <p>
                {{ code }}
            </p>
        </div>
        <div class="button">
            <img v-if="showCopied" class="copied-text-bubble" src="/assets/ui_elements/copied-text-bubble.svg" />
            <button @click="shareCode" @mousedown="playSound(soundConsts.COPY_INVITE_BUTTON)">
                <img class="icon" :src="iconSrc" />
            </button>
        </div>
    </div>
</template>
<script>
import { audioPlayer } from "/src/soundfx/audioPlayer.js";

export default {
    name: "ShareCode",
    props: { code: String, moduleName: String },
    mixins: [audioPlayer],

    data: () => ({
        showCopied: false,
    }),
    computed: {
        iconSrc: function () {
            return navigator.share ? "/assets/ui_elements/icon-share.svg" : "/assets/ui_elements/icon-copy.svg"
        }
    },
    methods: {
        appendLocaleIfNeeded: function (url, locale) {
            // Check if the URL already has a 'locale' query parameter
            const hasLocale = new URLSearchParams(url.split('?')[1]).has('locale');
            if (!hasLocale) {
                // Append 'locale' parameter if it's not present
                return url + (url.includes('?') ? '&' : '?') + 'locale=' + locale;
            }
            return url;
        },
        shareCode: function () {
            let urlToShare = window.location.href;

            if (navigator.share) {
                navigator.share({
                    title: this.$t('play-likely-story'),
                    text: this.$t('lets-play-likely-story-code', [this.code]),
                    url: ''
                });

                this.$gtm.trackEvent({
                    event: 'invite_players_click',
                    invite_method: 'share via text or email',
                    click_text: 'share icon',
                    module_name: this.moduleName,
                    room_code: this.code
                });

            } else {
                this.showCopied = true
                urlToShare = this.appendLocaleIfNeeded(urlToShare, this.$i18n.locale);
                navigator.
                    clipboard.writeText(this.$t('lets-play-likely-story-link',
                        [this.code, urlToShare]))
            }

            this.$gtm.trackEvent({
                event: 'invite_players_click',
                invite_method: 'copy url',
                click_text: 'copy icon',
                module_name: this.moduleName,
                room_code: this.code
            });

        },
        watch: {
            showCopied(showCopied) {
                if (showCopied) {
                    setTimeout(() => { this.showCopied = false }, [1500])
                }
            }
        }
    }
}

</script>
<style lang="scss" scoped>
.share-code-wrapper {
    display: flex;
    flex-direction: row;
}

.code {
    padding: 9px 20.5px;
    font-weight: 750;
    font-size: 48px;
    line-height: 63.07px;
    letter-spacing: 3px;
    color: var(--navy);
    background-color: var(--dark-cream);
    border-radius: 12px;
    margin-right: 8px;

    @media only screen and (min-width: 701px) {
        padding: 13.5px 31px;
        font-size: 72px;
        line-height: 94.6px;
    }
}

.button {
    background-color: var(--forest-200);
    border-radius: 12px;
    position: relative;
    display: flex;
    justify-content: center;

    button {
        padding: 27.5px 15.5px;
        letter-spacing: 3px;
        color: var(--navy);
    }

    .icon {
        height: 30px;
        width: 30px;
    }

    .copied-text-bubble {
        width: 99px;
        height: auto;
        position: absolute;
        top: -30px;
        right: 50%;
        transform: translateX(50%);
        z-index: 1000;
    }


    @media only screen and (min-width: 701px) {
        button {
            padding: 27.5px 23px;
        }

        .icon {
            height: 45px;
            width: 45px;
        }
    }

}
</style>