<template>
  <div class="gif-gallery__responses">
    <div v-for="player in players" v-bind:key="player" class="gif-gallery__response">
      <div class="gif-gallery__response-inner" :class="winnerSelected == submissions.get(player) && 'selected'">
        <img v-if="readyForJudgement" @click="doSubmit(player)" :src="submissions.get(player)" class="gif-gallery__gif"
          :data-ga-winner-gif="submissions.get(player)" />
        <div v-else-if="!readyForJudgement && submissions.get(player)" class="gif-gallery__not-ready">
          <img :src="submissions.get(player)" class="gif-gallery__gif" />
        </div>
        <div v-else class="gif-gallery__placeholder">
          <IconEllipsis />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconEllipsis from "../../icons/IconEllipsis";
import { audioPlayer } from "/src/soundfx/audioPlayer.js";

export default {
  name: "GifJudgement",
  mixins: [audioPlayer],
  components: {
    IconEllipsis,
  },
  props: {
    players: Array,
    submissions: Object,
    readyForJudgement: Boolean,
    submit: Function,
  },
  data: () => ({
    winnerSelected: false,
  }),
  methods: {
    doSubmit(player) {
      this.winnerSelected = this.submissions.get(player);
      setTimeout(() => {
        this.submit(player);
      }, 500);
    }
  },
  watch: {
    submissions: function () {
      this.playSound(this.soundConsts.OTHERS_SUBMISSION)
    },
  }
};
</script>

<style scoped lang="scss">
.gif-gallery__gif {
  cursor: pointer;
}

.gif-gallery__placeholder {
  background-color: var(--white);
  opacity: 0.5;
}

.gif-gallery__response-inner {
  &.selected {
    &::before {
      content: url('/assets/ui_elements/check.svg');
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      top: 50%;
      left: 50%;
      border-radius: 15px;
      background-color: var(--navy);
      transform: translate(-50%, -50%);
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: 0 0 0 3px var(--navy) inset;
      border-radius: var(--radius-small);
      background-color: rgba(44, 37, 82, 0.2);
      z-index: 1;
    }
  }
}
</style>
