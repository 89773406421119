<template>
  <div class="Splash">
    <Transition name="fade" v-show="!showPrompt">
      <header class="Splash__header">{{ $t('round-roundnumber', [roundNumber]) }}</header>
    </Transition>

    <div class="Splash__body">
      <Transition name="fadeOut" v-show="!showPrompt">
        <div class="Splash__circle">
          <div class="Splash__avatar">
            <Avatar :avatar-id="playerAvatars.get(judgeId)" />
          </div>
        </div>
      </Transition>
      <Transition name="fade" v-show="showPrompt">
        <div class="Splash__card">{{ topText }}</div>
      </Transition>
    </div>

    <Transition name="fade" v-show="!showPrompt">
      <footer class="Splash__footer">
        <template v-if="playerId == judgeId">
          {{ $t('now-youre-the-judge') }}
        </template>
        <template v-else>
          {{ $t('player-judgeid-is-judging', [judgeId]) }}
        </template>
      </footer>
    </Transition>
  </div>
</template>

<script>
import Avatar from "../Avatar";
import { audioPlayer } from "/src/soundfx/audioPlayer.js";

export default {
  name: "Splash",
  mixins: [audioPlayer],
  components: {
    Avatar
  },
  props: {
    judgeId: Number,
    roundNumber: Number,
    playerAvatars: Object,
    playerId: Number,
    topText: String,
  },
  data: () => ({
    showPrompt: false,
  }),
  mounted() {
    console.log("Splash mounted: roundNumber=" + this.roundNumber)
    let time = 3000;
    setTimeout(() => {
      this.showPrompt = true;
    }, time);

    if (this.roundNumber > 1) {
      this.playSound(this.soundConsts.INTERSTITIAL);
    }
  }
};
</script>

<style lang="scss" scoped>
.Splash {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  height: var(--app-height);
  background-color: var(--navy);
  background-image: url('/assets/backgrounds/bg-navy.png');
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--white);
  padding: var(--spacing-medium);

  &__header {
    font-family: var(--font-heading);
    font-size: 50px;
    line-height: 1.2;
    margin-bottom: var(--spacing);

    @media only screen and (min-width: 701px) {
      font-size: 72px;
      margin-bottom: var(--spacing-medium);
    }
  }

  &__body {
    font-family: var(--font-body);
    font-size: 18px;
    font-weight: 700;
  }

  &__card,
  &__circle {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &__card {
    padding: var(--spacing-medium) var(--spacing);
    border-radius: var(--radius-medium);
    line-height: 1.4;
    text-align: center;

    @media only screen and (min-width: 701px) {
      max-width: 620px;
      font-size: 28px;
    }
  }

  &__circle {
    padding: var(--spacing-medium);
    border-radius: 50%;
  }

  &__avatar {
    width: 80px;
    height: 80px;

    @media only screen and (min-width: 701px) {
      width: 108px;
      height: 108px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__footer {
    font-family: var(--font-body);
    font-size: 22px;
    font-weight: 700;
    margin-top: var(--spacing);

    @media only screen and (min-width: 701px) {
      font-size: 38px;
      margin-top: var(--spacing-large);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.16s $ease-3 0.33s;
  /* transform: scale(1); */
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  /* transform: scale(1.15); */
}

.fadeOut-enter-active,
.fadeOut-leave-active {
  transition: opacity 0.16s $ease-3;
  transform: scale(1);
}

.fadeOut-enter-from,
.fadeOut-leave-to {
  opacity: 0;
}
</style>
