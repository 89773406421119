<template>
  <Countdown v-if="roundNumber <= 1"
  :count-down-timer="countDownTimer"
  :players="players"
  />

  <Leaderboard
    v-else
    :gif="roundType == 'gif'"
    :last-round-winner="lastRoundWinner"
    :player-id="playerId"
    :players="players"
    :round-number="roundNumber"
    :num-rounds="numRounds"
    :scores="scores"
  />

  <!-- <Splash 
    v-if="showSplash"
    :judgeId="judgeId"
    :playerAvatars="playerAvatars"
    :playerId="playerId"
    :roundNumber="roundNumber"
    :topText="topText"
  /> -->
</template>

<script>
import Countdown from "./Countdown";
import Leaderboard from "./Leaderboard";
// import Splash from "./Splash";

export default {
  name: "PreGame",
  components: {
    Countdown, 
    Leaderboard,
    // Splash
  },
  data: () => ({
    // showSplash: false,
  }),
  props:{
    // countDownTimer: {
    //   default: 4,
    //   type: Number
    // },
    // judgeId: Number,
    // playerAvatars: Object,
    lastRoundWinner: Number,
    playerId: Number,
    roundNumber: Number,
    numRounds: Number,
    roundType: String,
    showTimer: Boolean,
    topText: String,
    players: Array,
    scores: Object,
  },
  mounted() {
    // let time = {
    //   countdown: this.countDownTimer || 0,
    //   leaderboard: 5000,
    //   splash: 5000,
    // };

    // if (time.countdown <= 1000) {
    //   time.countdown = time.countdown * 1000;
    // }

    // console.log('Countdown: ', time.countdown);
    // console.log('ShowSplash: ', this.showSplash);
    
    // setTimeout(() => {
    //   this.showSplash = true;
    //   console.log('ShowSplash: ', this.showSplash);
    // }, time.countdown);

    // console.log(time.leaderboard);
  }
}
</script>

<style>

</style>
