<template>
  <div class="container">
    <header>
      <div @click="$emit('prevPage')" class="link">
        <img src="/assets/ui_elements/arrowBack.svg" alt="back" />
      </div>
      <div @click="$emit('resetPage')" class="link">
        <img src="/assets/ui_elements/close.svg" alt="close" />
      </div>
    </header>
    <div class="hero">
      <img src="/assets/ui_elements/onboarding2.svg" class="sticker-regular image" />
    </div>
    <div class="body">
      <h2>{{ $t('take-turns-judging') }}</h2>
      <p><strong>{{ $t('everyone-takes-a-turn') }}</strong> {{ $t('as-the-secret-judge-the-judge-chooses-the') }}
        <strong>{{ $t('best-reaction-to-a-situation') }}</strong>{{ $t('players-who-chose-that-reaction-get-a-sticker') }}
      </p>
    </div>
    <footer>
      <Button class="primary large" @click="$emit('nextPage')" @mousedown="playSound(soundConsts.NEXT_BUTTON)">{{
        $t('next') }}</Button>
    </footer>
  </div>
</template>

<script>
import Button from "../../components/buttons/Button";
import { audioPlayer } from "/src/soundfx/audioPlayer.js";

export default {
  name: "Onboarding2",
  mixins: [audioPlayer],
  components: { Button },
  emits: ["nextPage", "prevPage", "resetPage"],
  created() {
    this.$gtm.trackEvent({
      event: 'game_isntructions_step_view',
      module_name: 'invite',
      instructions_step_number: 2
    });
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 22px;
  height: calc(100% - 44px);
  width: 100%;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.image {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.link {
  cursor: pointer;
}

.body {
  font-size: 16px;
  line-height: 1.37875em;
  text-align: center;

  h2 {
    font-weight: 400;
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    max-width: 10em;
    text-align: center;
    margin: 0 auto 1rem;
  }

  strong {
    font-weight: 800;
  }
}

footer {}
</style>
