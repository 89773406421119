<template>
  <div class="countdown">
    <div id="countdown__number">
      {{ countDownTimer }}
    </div>
  </div>
</template>

<script>

export default {
  name: "Countdown",
  components: {},
  mounted() {
    this.$gtm.trackEvent({
      event: 'game_start',
      number_players: this.players.length,
    });
  },
  props: {
    countDownTimer: Number,
    players: Array,
  },
};
</script>

<style scoped>
.countdown {
  color: var(--white);
  background-color: var(--navy);
  background-image: url('/assets/backgrounds/bg-navy.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
</style>
