<template>
  <div v-click-outside="onClose" class="popup-control">
    <div class="sound-icon" @click="toggleModal()">
      <img v-if="muted()" src="/assets/ui_elements/icon-sound-off.svg" class="icon-sound-off" />
      <img v-else src="/assets/ui_elements/icon-sound-on.svg" class="icon-sound-on" />
    </div>
    <div v-if="isOpen" class="sound-modal">
      <button type="button" @click="toggleSound()" :class="{ on: !soundMuted() }">{{ $t('sound-effects') }}</button>
      <button type="button" @click="toggleBackgroundMusic()" :class="{ on: !backgroundMusicMuted() }">{{ $t('music')
      }}</button>
    </div>
  </div>
</template>

<script>
import { soundStore } from '/src/soundfx/soundStore.js';
import { audioPlayer } from '/src/soundfx/audioPlayer.js';
import vClickOutside from 'click-outside-vue3'

export default {
  name: "SoundControl",
  mixins: [audioPlayer],
  data() {
    return {
      isOpen: false
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    toggleModal() {
      this.isOpen = !this.isOpen
    },
    onClose() {
      this.isOpen = false
    },
    toggleBackgroundMusic() {
      soundStore.toggleBackgroundMusic()

      // Added to keep BGM pause/play state in sync with soundStore BGM state
      if (this.backgroundMusicMuted()) {
        this.muteBackgroundMusic()
        this.$gtm.trackEvent({
          event: 'sound_music_off_click',
        });
      } else {
        this.unmuteBackgroundMusic()
        this.$gtm.trackEvent({
          event: 'sound_music_on_click',
        });
      }
    },
    toggleSound() {
      soundStore.toggleSound()

      // Track GTM after toggle
      if (this.soundMuted()) {
        this.$gtm.trackEvent({
          event: 'sound_effects_off_click',
        });
      } else {
        this.$gtm.trackEvent({
          event: 'sound_effects_on_click',
        });

      }
    },
  }
}
</script>

<style lang="scss">
.popup-control {
  position: relative;
  margin-left: 5px;
}

.sound-icon {
  display: flex;
  justify-content: center;
  min-width: 23px;
  cursor: pointer;


  .icon-sound-on {
    height: 21px;
    width: 21px;
  }

  .icon-sound-off {
    height: 21px;
    width: 23px;
  }

  @media only screen and (min-width: 701px) {
    min-width: 34.5px;

    .icon-sound-on {
      height: 31.5px;
      width: 31.5px;
    }

    .icon-sound-off {
      height: 31.5px;
      width: 34.5px;
    }
  }
}

.sound-modal {
  z-index: 8;
  position: absolute;
  right: -12px;
  bottom: -12px;
  transform: translateY(100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 181px;
  background-color: var(--white);
  border: 3px solid var(--navy);
  border-radius: 13px;
  padding: 12px;

  button {
    position: relative;
    font-size: 16px;
    line-height: 21px;
    font-family: 'rocgrotesk';
    font-weight: 700;
    color: var(--navy);
    text-align: left;
    padding: 6px;
    width: 100%;
    border-radius: 8px;

    &:first-child {
      margin-bottom: 8px;
    }


    // Sound on styling
    &.on {
      background-color: var(--forest-100);

      &:after {
        position: absolute;
        right: 6px;
        content: url('/assets/ui_elements/check.svg');
        filter: var(--filter-navy);
      }
    }

  }

}
</style>
