<template>
  <div class="Timer" :class="timeLeft <= 10 && 'warn'">
    :{{ formatedTime }}
  </div>
</template>
<script>
import { audioPlayer } from "/src/soundfx/audioPlayer.js";

export default {
  components: {
  },
  mixins: [audioPlayer],
  props: {
    max: Number,
    endTime: Date,
  },
  data: (props) => ({
    isMounted: false,
    timeLeft: props.max,
    ticker: null,
  }),
  computed: {
    formatedTime() {
      let n = Math.max(0, this.timeLeft);
      return (n < 10) ? ("0" + n) : n;
    }
  },
  methods: {
    tick() {
      if (!this.isMounted) {
        return;
      }
      this.timeLeft = Math.floor((this.endTime - (new Date().getTime())) / 1000);
      if (this.timeLeft == 10) {
        this.playTenSecondCountdown();
      }
      if (this.timeLeft < 0) {
        if (this.ticker) {
          clearInterval(this.ticker);
        }
        this.timeLeft = -1;
      }
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      this.isMounted = true;
      if (this.endTime) {
        this.ticker = setInterval(this.tick, 1000);
      }
    });
  },
  beforeUnmount() {
    this.stopTenSecondCountdown();
  },
  unmounted() {
    this.isMounted = false;
    if (this.ticker) {
      clearInterval(this.ticker);
    }
  }
}
</script>

<style lang="scss" scoped>
.Timer {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: var(--navy);
  padding: 4px 10px;
  border-radius: 9px;


  @media only screen and (min-width: 701px) {
    font-size: 26px;
  }

  &.warn {
    background-color: var(--red);
    color: var(--white);
  }
}
</style>
