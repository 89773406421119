<template>
  <transition name="slide-fade">
    <div class="HotTip">
      <div class="HotTip__container">
        <div class="HotTip__sticker">
          <div class="HotTip__animation">
            <Sticker image="/assets/ui_elements/hotTip.svg" id="sticker" :delay="0.15" />
          </div>
        </div>

        <transition name="fade" appear>
          <div class="HotTip__card">
            {{ hotTip }}
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
import Sticker from '../stickers/Sticker';

export default {
  name: "HotTip",
  components: { Sticker },
  props: {
    hotTip: String,
    players: Array,
    roundNumber: Number,
    numRounds: Number,
    tip: String,
  },
  mounted() {
    // Google Tag Manager Event
    this.$gtm.trackEvent({
      event: 'game_hot_tip_view',
      round_number: this.roundNumber,
      round_name: this.roundNumber === this.numRounds ? 'final round' : this.roundNumber,
      hot_tip_displayed: this.hotTip,
      number_players: this.players.length,
    });
  }
}
</script>

<style lang="scss" scoped>
.HotTip {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: var(--spacing-medium);
  background-color: var(--light-red);
  background-image: url('/assets/backgrounds/bg-lightRed.png');
  background-size: cover;
  background-repeat: repeat;
  z-index: 9;

  &__container {
    position: relative;
    width: 100%;

    @media only screen and (min-width: 701px) {
      max-width: 500px;
      margin: 0 auto;
    }
  }

  &__sticker {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-15%, -50%);
  }

  &__animation {
    animation: dance 1.5s $ease-3;
    animation-iteration-count: infinite;
  }

  &__card {
    background-color: var(--white);
    color: var(--navy);
    font-size: 18px;
    font-weight: 700;
    line-height: 1.344;
    text-align: center;
    padding: var(--spacing-large) var(--spacing);
    border-radius: var(--radius-large);
    border: 3px solid var(--navy);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.16s $ease-3 0.33s, transform 0.16s $ease-3 0.33s;
  transform: scale(1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scale(0.15);
}

.slide-fade-enter-active {
  transition: all 0.3s $ease-3 0.33s;
}

.slide-fade-leave-active {
  transition: all 0.8s $ease-3 0.33s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-80vw);
  opacity: 1;
}
</style>