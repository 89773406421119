<template>
  <transition name="fade">
    <div class="modal" v-if="show">
      <div class="modal__backdrop" v-if="noexit"/>
      <div class="modal__backdrop" @click="closeModal()" v-if="!noexit"/>

      <div class="modal__dialog">
        <div class="modal__header">
          <slot name="header"/>
          <!-- <button type="button" class="modal__close" @click="closeModal()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
              <path
                fill="currentColor"
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
              ></path>
            </svg>
          </button> -->
        </div>

        <div class="modal__body">
          <slot name="body"/>
        </div>

        <div class="modal__footer">
          <slot name="footer"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: {
    noexit: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      document.querySelector('body').classList.remove('overflow-hidden');
    },
    openModal() {
      this.show = true;
      document.querySelector('body').classList.add('overflow-hidden');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;

  &__backdrop {
    background-color: var(--navy);
    opacity: 0.5;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &__dialog {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: var(--app-height);
    background-color: var(--light-cream);
    overflow: hidden;
    
    /* margin: 50px auto; */
    border-radius: 5px;
    z-index: 2;
    
    @media only screen and (min-width: 701px) {
      max-width: 675px;
      height: calc(100vh - 10vh);
      margin: 5vh auto;
      padding: var(--spacing);
    }
  }

  &__close {
    width: 30px;
    height: 30px;
  }

  &__header {
    padding: 20px 20px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex: none;
  }

  &__body {
    position: relative;
    padding: 10px 20px 10px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex: auto;
    flex-direction: column;
    align-items: stretch;
    -webkit-overflow-scrolling: touch;
  }
  
  &__footer {
    position: relative;
    padding: 0 20px 20px;
    flex: none;

    &::after {
      content: '';
      background: linear-gradient(to bottom, rgba(255,255,255,0), var(--light-cream));
      width: 100%;
      height: 100px;
      position: absolute;
      top: -100px;
      left: 0;
      pointer-events: none;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>