<template>
  <div v-if="lowOnTime">{{warning}}</div>
  <div v-else>{{text}}</div>
</template>

<script>
export default {
  name: "LowTimeWarning",
  props: {
    text: String,
    warning: String,
    endTime: Object,
    threshold: Number,
  },
  data: () => ({
    timeLeft: 1000,
    ticker: null,
  }),
  computed: {
    lowOnTime() {
      return this.timeLeft < this.threshold;
    },
  },
  methods: {
    tick() {
      if (!this.isMounted) {
        return;
      }
      this.timeLeft = Math.floor((this.endTime - (new Date().getTime())) / 1000);
      if (this.timeLeft < 0) {
        if (this.ticker) {
          clearInterval(this.ticker);
        }
        this.timeLeft = -1;
      }
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      this.isMounted = true;
      if(this.endTime){
        this.ticker = setInterval(this.tick,  200);
      }
    });
  },
  unmounted() {
    this.isMounted = false;
    if (this.ticker) {
      clearInterval(this.ticker);
    }
  }
}
</script>

<style scoped>
</style>
