<template>
  <!--
    WAITING ROOM
    Where players wait for the game to start
  -->
  <SplashScreen v-show="shouldShowSplashScreen" />
  <div v-show="!shouldShowSplashScreen" class="waiting-room">
    <Header class="clear xs-logo-phone">
      <template v-slot:center>
        <div />
      </template>
      <template v-slot:right>
        <CodeToggleButton v-if="!isSinglePlayerMode" @mousedown="playSound(soundConsts.INVITE_BUTTON)"
          @click="$refs.drawerShareCode.openModal()" :code="roomCode" />
        <SoundControl />
        <LanguageSwitcher v-if="isSinglePlayerMode" />
      </template>
    </Header>

    <div class="waiting-room__wrapper">

      <div id="waiting-room__players" class="waiting-room__players">
        <div class="waiting-room__player" v-for="player in players" v-bind:key="player">
          <div style="position: relative; width: 100%;display: flex;justify-content: center;">
            <div v-if="player === playerId" class="waiting-room__thats-you">
              <span>{{ $t('youre-player-number', [player]) }}</span>
              <img src="/assets/ui_elements/icon-thatsYou.svg" />
            </div>
            <Avatar v-if="player === playerId" :avatar-id="playerAvatars.get(player)" class="waiting-room__avatar you"
              :class="playerAvatars.get(player) == 0 ? 'no-avatar' : ''" @click="$refs.pickAvatar.openModal()"
              @mousedown="playSound(soundConsts.GENERIC_BUTTON)" />
            <Avatar v-else :avatar-id="playerAvatars.get(player)" class="waiting-room__avatar"
              :class="playerAvatars.get(player) == 0 ? 'no-avatar' : ''" />
          </div>
        </div>
        <div class="waiting-room__player">
          <div class="waiting-room__no-avatar" />
        </div>
        <div class="waiting-room__player">
          <div class="waiting-room__no-avatar" />
        </div>
        <div class="waiting-room__player">
          <div class="waiting-room__no-avatar" />
        </div>
        <div class="waiting-room__player">
          <div class="waiting-room__no-avatar" />
        </div>
        <div class="waiting-room__player">
          <div class="waiting-room__no-avatar" />
        </div>
        <div class="waiting-room__player">
          <div class="waiting-room__no-avatar" />
        </div>
      </div>

      <div class="waiting-room__controls">
        <img src="/assets/ui_elements/phoneSticker.svg" class="waiting-room__sticker-top" />
        <div class="waiting-room__messaging">
          <span v-show="isHost">{{ $t('youre-the-host') }}</span>
          {{ playerCountAdvice }}
          <span v-show="isHost && !enoughPlayers">{{ $t('invite-at-least-3-players-length-more-players-to-start-a-game',
            [3 - players.length]) }}</span>
          <span v-show="isHost && enoughPlayers && !allBlobsChosen">{{
            $t('waiting-on-players-to-choose-their-blobs-is-everyone-here') }}</span>
          <span v-show="isHost && enoughPlayers && allBlobsChosen">{{ $t('tap-play-to-start-the-game') }}</span>
          <span v-show="!isHost && !enoughPlayers">{{ $t('the-host-will-start-game-once-3-or-more-players')
          }}</span>
          <span v-show="!isHost && enoughPlayers && !allBlobsChosen">{{
            $t('the-host-will-start-game-once-3-or-more-players') }}</span>
          <span v-show="!isHost && enoughPlayers && allBlobsChosen">{{ $t('waiting-for-the-host-to-start-the-game')
          }}</span>
        </div>

        <div class="waiting-room__ctas"
          :class="{ ready: enoughPlayers && isHost && allBlobsChosen && !isSinglePlayerMode }">
          <Button v-if="!isSinglePlayerMode" class="primary large" @click="$refs.drawerShareCode.openModal()"
            ga-button="Invite player modal" @mousedown="playSound(soundConsts.INVITE_BUTTON)">{{ $t('invite') }}</Button>
          <Button v-if="enoughPlayers && isHost && allBlobsChosen" @click="startGame"
            @mousedown="playSound(soundConsts.PLAY_BUTTON)" class="secondary large" ga-button="Start Game">{{ $t('play')
            }}</Button>
        </div>
        <img src="/assets/ui_elements/waitingRoomRight.svg" class="waiting-room__sticker-bottom" />
      </div>
    </div>

    <!-- SHARE CODE MODAL  -->
    <FullScreenModal ref="hostShareInitialCode" noexit v-if="!isSinglePlayerMode">
      <template v-slot:body>
        <div class="host-share-code-body">
          <h1 class="welcome-text">{{ $t('welcome-to-room') }}</h1>
          <div class="language-switcher-container" v-if="this.players.length <= 1">
            <span class="language-switcher-text">Choose game language for all players</span>
            <LanguageSwitcher />
          </div>
          <ShareCode :code="roomCode" moduleName="Welcome to room screen" />
          <p class="instructions">{{ $t('join-the-game-or-invite-friends-to-go-to') }} <span
              class="bold">www.likelystory.game</span>
            {{ $t('and-enter-the-room-code') }}</p>
        </div>
      </template>

      <template v-slot:footer>
        <Button class="primary large host-share-code-button" @click="showAvatarModal"
          @mousedown="playSound(soundConsts.GENERIC_BUTTON);" ga-button="Next">
          {{ $t('next') }}
        </Button>
      </template>

    </FullScreenModal>

    <!-- PICK AN AVATAR MODAL -->
    <Modal ref="pickAvatar" noexit>
      <template v-slot:header>
        <h2 class="modal-header">{{ $t('pick-out-a-blob') }}</h2>
      </template>
      <template v-slot:body>
        <div class="avatar-grid">
          <div class="avatar-each" v-for="(avatar, index) in avatarList" :key="avatar.url">
            <label @mousedown="playSound(soundConsts.WR_BLOB_PICKED + (index + 1))"
              @click="$emit('chooseAvatar', index + 1); chosenAvatar = index + 1"
              :class="[(chosenAvatar == index + 1 ? 'active' : ''), (chosenAvatars.includes(index + 1) ? 'disabled' : '')]"
              data-ga-button="Choose blob">
              <input name="avatarGrid" type="radio" :value="avatar.url" data-ga-button="Choose blob" />
              <div class="avatar-each__bg"></div>
              <Avatar :avatar-id="index + 1" />
            </label>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <Button class="primary large avatar-button" :class="!chosenAvatar && 'disabled'" @click="selectAvatar"
          @mousedown="playSound(soundConsts.GENERIC_BUTTON); playBackgroundMusic(soundConsts.BG_MUSIC_WAITING_ROOM);"
          ga-button="Close blob selection">
          {{ $t('select') }}
        </Button>
      </template>
    </Modal>

    <!-- SHARE CODE MODAL -->
    <DrawerModal ref="drawerShareCode" v-if="!isSinglePlayerMode">
      <template v-slot:header>
        <h2 class="modal-header">{{ $t('welcome-to-room') }}</h2>
        <img class="modal-header__close" @click="$refs.drawerShareCode.closeModal()" src="/assets/ui_elements/ex.svg"
          alt="close" />
      </template>
      <template v-slot:body>
        <div class="drawer-share-code__body">
          <div class="share-button">
            <ShareCode :code="roomCode" moduleName="Invite drawer" />
          </div>
          <p class="instructions">{{ $t('invite-friends-to-go-to') }} <span class="bold">www.likelystory.game</span>
            {{ $t('and-enter-the-room-code') }}</p>

          <Button class="primary done-button" @click="$refs.drawerShareCode.closeModal()"
            @mousedown="playSound(soundConsts.GENERIC_BUTTON)">
            {{ $t('done') }}
          </Button>
        </div>

      </template>
    </DrawerModal>

    <Onboarding v-show="showOnboarding" @close-onboarding="closeOnboarding" :room-code="roomCode" />
  </div>
</template>

<script>
import Header from "/src/components/header";
import Avatar from "/src/components/Avatar";
import Button from "/src/components/buttons/Button";
import Modal from "/src/components/modal";
import DrawerModal from "/src/components/modal/drawer-modal";
import FullScreenModal from "/src/components/modal/full-screen-modal";
import Onboarding from "/src/components/Onboarding";
import { audioPlayer } from "/src/soundfx/audioPlayer.js";
import ShareCode from "/src/components/WaitingRoom/ShareCode";
import CodeToggleButton from "./CodeToggleButton.vue";
import SoundControl from "../Soundcontrol/SoundControl.vue";
import SplashScreen from '/src/SplashScreen'
import LanguageSwitcher from "../language/LanguageSwitcher.vue";

export default {
  name: "WaitingRoom",
  mixins: [audioPlayer],
  components: {
    Avatar,
    Button,
    DrawerModal,
    FullScreenModal,
    Header,
    Modal,
    Onboarding,
    ShareCode,
    CodeToggleButton,
    SoundControl,
    SplashScreen,
    LanguageSwitcher
  },
  props: {
    playerId: Number,
    ready: Boolean,
    players: Array,
    playerAvatars: Object,
    roomCode: String,
  },
  emits: ["isReady", "chooseAvatar"],
  data: () => ({
    chosenAvatar: 0,
    showPrivacyBanner: false,
    showPrivacyModal: false,
    allBlobsChosen: false,
    showOnboarding: false,
    wasInitialModalShown: false,
    isHost: null,
  }),
  beforeCreate() {
    this.$gtm.trackEvent({
      event: 'dataLayer_initialized',
      page_name: 'waiting room',
    });
  },
  mounted() {
    window.scrollTo(0, 0);

    // Place GTM in timeout to obtain playerId
    setTimeout(() => {
      this.$gtm.trackEvent({
        event: 'waiting_room_view',
        blob_name: `Player ${this.playerId}`
      });
    }, 1000);

    this.$nextTick(() => {
      console.log("waiting room mounted isSinglePlayerMode", this.isSinglePlayerMode);
      if (this.isSinglePlayerMode) {
        this.$refs.pickAvatar.openModal();
      }
    });
  },
  beforeUnmount() {
    this.stopBackgroundMusic();
  },
  computed: {
    shouldShowSplashScreen: function () {
      if (this.isSinglePlayerMode) {
        return false;
      }
      return this.isHost === null
    },
    avatarList: function () {
      return this.$cms.avatars.filter((avatar, index) => index >= 1);
    },
    chosenAvatars: function () {
      return (this.playerAvatars && this.playerAvatars.values) ? Array.from(this.playerAvatars.values()) : [];
    },
    enoughPlayers: function () {
      return this.players.length >= 3;
    },
    emailHref: function () {
      return (
        "mailto:?subject=Play%20That's%20Sus%20With%20Me!&body=Let%27s%20play%20That%27s%20Sus!%20" +
        encodeURIComponent(window.location.origin + window.location.pathname)
      );
    },
    // isHost: function () {
    //   const host = Object.values(this.players).sort()[0]
    //   return this.playerId == host;
    // },
    phoneHref: function () {
      return (
        "sms:&body=Let%27s%20play%20Likely%20Story! " +
        encodeURIComponent(window.location.origin + window.location.pathname)
      );
    },
    playerCountAdvice: function () {
      if (!this || !this.players) {
        return "anananana";
      }
      if (this.players.length < 3) {
        return "";
      } else if (this.players.length < 7) {
        return "";
      } else {
        return "It's a party! You've reached your max number of players";
      }
    },
    roomFull: function () {
      return this.players.length >= 6;
    },
    locationString: () => window.location.origin + window.location.pathname,
    isSinglePlayerMode: function () {
      return this.$route.query.singlePlayer === 'true';
    },
  },
  methods: {
    focusChanged(event) {
      console.log("waitinroom focuschange: " + event)
    },
    closeOnboarding: function () {
      this.showOnboarding = false;
    },
    selectAvatar: function () {
      if (this.chosenAvatar) {
        this.$refs.pickAvatar.closeModal();
      }
      this.$gtm.trackEvent({
        event: 'select_avatar_click',
      });
    },
    startGame: function () {
      this.$gtm.trackEvent({
        event: 'host_start_click',
        click_text: 'Play',
      });
      this.$emit('isReady');
    },
    showAvatarModal: function () {
      this.$refs.hostShareInitialCode.closeModal()
      this.$refs.pickAvatar.openModal();
    },
  },
  watch: {
    playerAvatars: function (newVal) {
      const newBlobs = Array.from(newVal.values());

      if (newBlobs.indexOf(0) !== -1) {
        this.allBlobsChosen = false;
      } else {
        this.allBlobsChosen = true;
      }
    },
    playerId: function () {
      if (!this.$cookies.isKey('token')) {
        if (this.playerId > 1) {
          this.showOnboarding = true;
          this.$cookies.set('token');

          this.$gtm.trackEvent({
            event: 'join_game',
            module_name: 'invite',
            room_code: this.roomCode,
            click_text: 'Join the game'
          });
        }
      }

      // Determine if host
      const host = Object.values(this.players).sort()[0]
      this.isHost = this.playerId == host;
    },
    players: function (newVal, oldVal) {
      if (newVal.length >= oldVal.length &&
        this.playerAvatars.get(newVal[newVal.length - 1]) != 0 &&
        newVal[newVal.length - 1] != this.playerId
      ) {
        // play "blob joined sound" when new players join in after picking their blob
        this.playSound(this.soundConsts.WR_BLOB_JOINED + newVal[newVal.length - 1])
      }
    },
    isHost: function () {
      // Show initial modal once isHost is set
      if (this.wasInitialModalShown) {
        return
      }
      if (this.isHost) {
        this.$refs.hostShareInitialCode.openModal()
      } else {
        this.$refs.pickAvatar.openModal();
      }
      this.wasInitialModalShown = true
    }
  }
};
</script>

<style lang="scss" scoped>
.host-share-code-body {
  color: var(--navy);
  display: flex;
  flex-direction: column;
  align-items: center;

  .welcome-text {
    font-weight: 750;
    font-size: 26px;
    line-height: 34px;
    padding-bottom: 30px;
  }

  .instructions {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    margin-top: 30px;
    max-width: 408px;
  }

  .bold {
    font-weight: 750;
  }

  @media only screen and (min-width: 701px) {
    .welcome-text {
      font-size: 39px;
      line-height: 51px;
      padding-bottom: 45px;
    }

    .instructions {
      font-size: 24px;
      line-height: 31.5px;
      margin-top: 45px;
    }
  }
}

.host-share-code-button {
  width: 326px;
}

.drawer-share-code__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .share-button {
    padding-top: 10px;
    padding-bottom: 30px;
  }

  .instructions {
    color: var(--navy);
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    margin-bottom: 38px;
    max-width: 304px;

    .bold {
      font-weight: 750;
    }
  }

  @media only screen and (min-width: 701px) {
    .share-button {
      padding-top: 25px;
      padding-bottom: 45px;
    }

    .instructions {
      font-size: 24px;
      line-height: 31.5px;
      margin-bottom: 45px;
      max-width: 408px;
    }
  }

  .done-button {
    width: 100%;
    max-width: 326px;

    @media only screen and (min-width: 701px) {
      display: none;
    }
  }
}


.avatar-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-column-gap: 2rem;
  grid-row-gap: 2rem; */
  width: 90%;
  margin: 0 auto;

  @media only screen and (min-width: 701px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0 var(--spacing-large);
    min-height: 100%;
  }
}

.button.primary.large.avatar-button {
  max-width: 300px;
  margin: 0 auto;
}

.avatar-each {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-small);

  &:hover {
    background-color: var(--navy);
    border-radius: 15px;
  }

  input {
    display: none;
  }

  label {
    &.active {
      .avatar-each__bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        background: var(--navy);
        border-radius: var(--radius-medium);
        transition: background ease-in 140ms;
        z-index: -1;
      }
    }

    &.disabled {
      pointer-events: none;

      &:not(.active) {
        img {
          opacity: 0.15;
        }
      }

      input {
        pointer-events: none;
      }
    }
  }

  input {
    &:checked+.avatar-each__bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      background: var(--navy);
      border-radius: var(--radius-medium);
      transition: background ease-in 140ms;
      z-index: -1;
    }
  }

  img {
    width: 100%;
    cursor: pointer;
  }
}

.waiting-room {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  height: var(--app-height);

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: auto;
    height: 100%;
    padding: var(--spacing);

    @media only screen and (min-width: 701px) {
      display: flex;
      flex-direction: row;
      padding: 0 var(--spacing-large) var(--spacing-large);
    }
  }

  &__players {
    flex: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    column-gap: var(--spacing);
    width: 65%;
    margin: 0 auto;

    @media only screen and (min-width: 701px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin: var(--spacing-large) var(--spacing-medium)var(--spacing-large) 0;
    }

    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 140px;
    max-width: 80%;
    margin: 0 auto;

    @media only screen and (max-width: 700px) {
      max-width: 100%;
      padding: 40px 0 30px;
    } */
  }

  &__player {
    display: none;
    position: relative;
    text-align: center;

    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4),
    &:nth-of-type(5),
    &:nth-of-type(6) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media only screen and (min-width: 701px) {
      width: 20%;
    }

    .waiting-room__avatar {
      max-height: 130px;
      max-width: 130px;
      width: 75%;

      &.no-avatar {
        width: 50%;
      }

      &.you {
        cursor: pointer;
      }
    }

    /* width: 150px;
    text-align: center;
    font-size: 24px;
    margin: 50px 35px;
    position: relative;

    @media only screen and (max-width: 700px) {
      width: 47px;
      font-size: 12px;
      margin: 1rem;
    } */
  }

  &__avatar {
    margin-bottom: 20px;
    width: 150px;

    @media only screen and (max-width: 700px) {
      margin-bottom: 1em;
    }
  }

  &__no-avatar {
    width: 50%;
    padding-bottom: 50%;
    background-color: var(--dark-cream);
    border-radius: 50%;
  }

  &__thats-you {
    position: absolute;
    max-width: none;
    top: -20px;
    right: -10vw;
    text-align: center;
    /* transform: translateX(-50%); */

    @media only screen and (min-width: 701px) {
      right: -4vw;
    }

    span {
      display: block;
      white-space: nowrap;
      font-size: 3vw;

      @media only screen and (min-width: 701px) {
        font-size: 1.2vw;
      }
    }
  }

  &__controls {
    flex: none;
    border-radius: var(--radius-large);

    @media only screen and (min-width: 701px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 35%;
      background-color: var(--green);
      padding: var(--spacing-medium);
      position: relative;
      overflow: hidden;
    }
  }

  &__sticker-top {
    display: none;

    @media only screen and (min-width: 701px) {
      display: block;
      position: absolute;
      transform: rotate(11deg);
      width: 100px;
      top: 20px;
      left: -29px;
    }
  }

  &__sticker-bottom {
    display: none;

    @media only screen and (min-width: 701px) {
      display: block;
      position: absolute;
      width: 150px;
      bottom: -18px;
      right: -13px;
    }
  }

  &__ctas {
    display: flex;
    flex-direction: row;

    &.ready {
      >.button {
        width: 50%;
      }
    }

    >.button {
      width: 100%;
    }

    .button {
      +.button {
        margin-left: var(--spacing-small);
      }
    }
  }

  .drawerModal__dialog {
    justify-content: center;
  }
}

// .waiting-room

.waiting-room__messaging {
  background: var(--cream);
  padding: var(--spacing);
  margin-bottom: var(--spacing-small);
  border-radius: var(--radius-medium);
  font-family: 'rocgrotesk', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.275;
  text-align: center;

  @media only screen and (min-width: 701px) {
    padding: 50px 25px;
  }
}

#waiting-room__invite {
  width: 50%;
  background-color: var(--dark-green);
  padding: 55px 60px 70px;
  z-index: 2;

  @media only screen and (max-width: 950px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 55px 0 70px;
  }

  @media only screen and (max-width: 700px) {
    padding: 15px 0 40px;
  }

  h2 {
    font-size: 35px;
    line-height: 46px;
    font-weight: 700;
    color: var(--white);
    margin-bottom: 37px;

    @media only screen and (max-width: 700px) {
      font-size: 17px;
      line-height: 2em;
      margin-bottom: 1rem;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    color: var(--white);
    margin-bottom: 15px;

    @media only screen and (max-width: 700px) {
      display: none;
    }
  }
}

#waiting-room__social {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 60px;
  max-width: 460px;

  @media only screen and (max-width: 950px) {
    width: 100%;
  }

  @media only screen and (max-width: 700px) {
    max-width: 100%;
    margin-bottom: 1.4rem;
  }

  img {
    @media only screen and (max-width: 700px) {
      max-width: 45px;
    }
  }
}



#waiting-room__status {
  width: 50%;
  background-color: var(--pink);
  padding: 125px 60px 70px;
  position: relative;

  @media only screen and (max-width: 950px) {
    width: 100%;
    padding: 125px 0 70px;
  }

  @media only screen and (max-width: 700px) {
    padding: 3rem 0 4rem;
  }
}

#waiting-room__player-count {
  color: var(--white);
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 40px;

  @media only screen and (max-width: 700px) {
    font-size: 17px;
    margin-bottom: 2rem;
  }
}

.modal-header {
  font-size: 26px;
  line-height: 34px;
  text-align: center;
  font-weight: 750;
  width: 100%;
  color: var(--navy);

  @media only screen and (min-width: 701px) {
    font-size: 39px;
    line-height: 51px;
  }

  &__close {
    display: none;

    @media only screen and (min-width: 701px) {
      display: block;
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
    }
  }
}

.drawer-copy {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 1em;
  text-align: center;

  @media only screen and (min-width: 701px) {
    margin: 65px 0 50px;
  }

  +.button {
    margin-top: 1em;

    @media only screen and (min-width: 701px) {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 460px;
    }
  }
}

.button-icon {
  margin-right: var(--spacing-small);
}

:deep(.popup-control) {
  margin-left: 10px;

  @media only screen and (min-width: 375px) {
    margin-left: 29px;
  }
}

.language-switcher-container {
  display: flex;
  align-items: center;
  gap: 0px;
  margin-bottom: 40px;
}

.language-switcher-text {
  color: var(--navy);
  font-size: 16px;
  font-weight: 500;
}
</style>
