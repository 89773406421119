<template>
    <div class="button">
        <span class="label">Room Code</span>
        {{ code }}
        <img class="icon" src='/assets/ui_elements/icon-info.svg' />
    </div>
</template>
<script>
export default {
    name: "CodeToggleButton",
    props: { code: String },
}
</script>
<style lang="scss" scoped>
.button {
    cursor: pointer;
    background-color: var(--forest-200);
    border-radius: 12px;
    position: relative;
    padding: 5px 13px;
    display: flex;
    align-content: center;
    font-weight: 750;
    font-size: 20px;
    line-height: 26px;
    color: var(--navy);
    flex-shrink: 0;
    align-items: center;

    .label {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        margin-right: 5px;
    }

    .icon {
        height: 11px;
        width: 11px;
        margin-bottom: 2px;
    }

    @media only screen and (min-width: 701px) {
        font-size: 30px;
        line-height: 39.5px;
        padding: 7.5px 19.5px 4.5px 19.5px;

        .label {
            font-size: 18px;
            line-height: 23.5px;
            margin-right: 12.5px;
        }

        .icon {
            height: 16.5px;
            width: 16.5px;
            margin-left: 5px;
            margin-bottom: 4px;
        }
    }

}
</style>