<template>
  <!--
    LEADERBOARD
    Shows list of players and their points
  -->
  <div class="leaderboard" v-bind:class="{ 'forest-bg': gif }">
    <div id="leaderboard__scores" class="leaderboard__scores">

      <transition-group name="list-complete" tag="p">
        <div class="leaderboard__score list-complete-item" v-for="(player, index) in playerOrder"
          v-bind:key="player.number">
          <div class="leaderboard__score-card">
            <div class="leaderboard__sticker" v-if="index === 0">
              <Sticker image="/assets/ui_elements/inTheLead.svg" id="sticker" :delay="0.15" />
            </div>
            <div class="leaderboard__score-inner">
              <div class="leaderboard__score-header">
                <Avatar :avatar-id="playerAvatars.get(player.number)" class="leaderboard__avatar" />
                {{ $t('player-player-number', [player.number]) }}
              </div>
              <div class="leaderboard__score-total">
                <span class="leaderboard__number">{{ scores[player.number] || 0 }}</span>
              </div>
            </div>
          </div>
        </div>
      </transition-group>

    </div>
  </div>
</template>

<script>
import Avatar from "../../Avatar";
import Sticker from "../../stickers/Sticker";
import { audioPlayer } from "/src/soundfx/audioPlayer.js";

export default {
  name: "Leaderboard",
  mixins: [audioPlayer],
  components: {
    Avatar,
    Sticker,
  },
  props: {
    lastRoundWinner: Number,
    playerId: Number,
    roundNumber: Number,
    numRounds: Number,
    showTimer: Boolean,
    players: Array,
    playerAvatars: Object,
    scores: Object,
    gif: Boolean,
  },
  data: () => ({
    playerOrder: [],
  }),
  mounted() {
    this.playSound(this.soundConsts.IN_THE_LEAD_STICKER)
    this.players.forEach(player => this.playerOrder.push({
      number: player,
      score: this.scores[player] || 0,
    }));

    this.playerOrder.sort((a, b) => {
      return b.score - a.score;
    })

    console.log(this.playerOrder);

    this.$gtm.trackEvent({
      event: 'game_leaderboard_view',
      round_number: this.roundNumber,
      round_name: this.roundNumber === this.numRounds ? 'final round' : this.roundNumber,
      number_players: this.players.length,
    });
  }
};
</script>

<style lang="scss">
.leaderboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--navy);
  background-image: url('/assets/backgrounds/bg-navy.png');
  background-size: cover;
  background-repeat: repeat;
  padding: var(--spacing-medium);

  &__scores,
  &__scores p {
    width: 100%;
    max-width: 400px;
    margin-right: -24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: var(--spacing) var(--spacing);

    @media only screen and (min-width: 701px) {
      width: 95%;
      max-width: 840px;
      margin: 0 auto;
      margin-right: auto;
      justify-content: flex-start;
      gap: var(--spacing-medium) var(--spacing-medium);
    }
  }

  &__score {
    width: calc(50% - var(--spacing));

    @media only screen and (min-width: 701px) {
      width: calc(33.33% - var(--spacing-medium));
    }

    @media only screen and (min-width: 880px) {
      width: calc(25% - var(--spacing-medium));
    }
  }

  &__score-card {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 140.625%;
    /* height: 120px; */
    /* margin: 0 15px 35px 15px; */
    border-radius: 15px;
    background-color: var(--light-green);

    @media only screen and (min-width: 701px) {}
  }

  &__score-inner {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__score-header {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    padding: 20px 20px 0;
  }

  &__avatar {
    width: auto;
    height: 52px;
    margin-bottom: var(--spacing);

    @media only screen and (min-width: 701px) {
      width: 75px;
      height: 75px;
    }
  }

  &__score-total {
    flex: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 67px;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    background-color: var(--white);
    color: var(--navy);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    @media only screen and (min-width: 701px) {
      font-size: 35px;
    }
  }

  &__sticker {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-20%, -50%);

    #sticker {
      width: 170px;
    }
  }
}

.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}

.list-complete-enter,
.list-complete-leave-to

/* .list-complete-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform: translateY(30px);
}

.list-complete-leave-active {
  position: absolute;
}
</style>
