<template>
    <div class="SplashScreen">
        <div class="SplashScreen__top-stickers">
            <img class="eyes_sticker" src="/assets/ui_elements/eyesSticker.svg" />
            <img class="phone_sticker" src="/assets/ui_elements/phoneSticker.svg" />
        </div>
        <img class="SplashScreen__logo" src="/assets/logos/likely-story-logo.gif" />
        <div class="SplashScreen__bottom-stickers">
            <img class="rip_sticker" src="/assets/ui_elements/splash/rip-me-cutoff.svg" />
            <img class="flower_sticker" src="/assets/ui_elements/splash/green-flower-cutoff.svg" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'SplashScreen'
}
</script>
<style lang="scss" scoped>
.SplashScreen {
    position: relative;
    width: 100%;
    height: 100vh;

    &__logo {
        width: 210px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .eyes_sticker {
        position: absolute;
        top: -26px;
        right: -14px;
        width: 100px;
        z-index: 11;
    }

    .phone_sticker {
        position: absolute;
        top: -10px;
        right: -50px;
        width: 100px;
    }

    .rip_sticker {
        position: absolute;
        bottom: 22px;
        width: 55px;
        z-index: 11;
    }

    .flower_sticker {
        position: absolute;
        bottom: 0;
        left: 19px;
        width: 114px;
    }

}
</style>