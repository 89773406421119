<template>
  <div className="game">
    <FullRoom v-if="playerId < 0" @new-game="newGame" @main-page="mainPage" />
    <WaitingRoom id="waiting-room" v-else-if="roundNumber == -1" :room-code="roomCode" :players="players"
      :player-avatars="playerAvatars" :player-id="playerId" :ready="ready" @is-ready="isReady"
      @choose-avatar="chooseAvatar" />
    <Round v-else-if="roundNumber >= 0 && roundNumber < numRounds" :current-round="currentRound"
      :did-submit="lastSubmit == roundNumber" :last-round-winner="lastRoundWinner" :playerId="playerId" :players="players"
      :player-avatars="playerAvatars" :submissions="submissions" :winner-id="roundWinner" :winner-sticker="roundSticker"
      :scores="scores" @submit="doSubmit" :num-rounds="numRounds" />
    <EndGame id="endgame" v-else :winner-id="winnerId" :player-avatars="playerAvatars" :players="players"
      @new-game="newGame" @main-page="mainPage" @end-game="goHome" :scores="scores" :room-id="roomId" />
  </div>
</template>

<script>
import FullRoom from "./components/FullRoom";
import WaitingRoom from "./components/WaitingRoom";
import EndGame from "./components/EndGame";
import { Submission } from "./proto/game_pb";
import Round from "./components/round";

export default {
  name: "ThatsSus",
  components: {
    EndGame,
    FullRoom,
    Round,
    WaitingRoom,
  },
  emits: ["newGame", "submit", "mainPage"],
  props: {
    submit: Function,
    playerContext: Object,
    roomCode: String,
    roomId: String,
  },
  data: () => ({
    ready: false,
    lastSubmit: null,
    playerAvatars: {},
    players: [],
    playerId: 0,
    roundNumber: -1,
    numRounds: 10,
    submissions: {},
    currentRound: {},
    roundWinners: {},
    roundWinner: null,
    roundStickers: [],
    roundSticker: null,
    stickers: [
      "/assets/stickers/sticker_01.svg",
      "/assets/stickers/sticker_02.svg",
      "/assets/stickers/sticker_03.svg",
      "/assets/stickers/sticker_04.svg",
      "/assets/stickers/sticker_05.svg",
      "/assets/stickers/sticker_06.svg",
      "/assets/stickers/sticker_07.svg",
      "/assets/stickers/sticker_08.svg",
      "/assets/stickers/sticker_09.svg",
      "/assets/stickers/sticker_10.svg",
      "/assets/stickers/sticker_11.svg",
      "/assets/stickers/sticker_12.svg",
      "/assets/stickers/sticker_13.svg",
      "/assets/stickers/sticker_14.svg",
      "/assets/stickers/sticker_15.svg",
      "/assets/stickers/sticker_16.svg",
      "/assets/stickers/sticker_17.svg",
      "/assets/stickers/sticker_18.svg",
      "/assets/stickers/sticker_19.svg",
      "/assets/stickers/sticker_20.svg",
      "/assets/stickers/sticker_21.svg",
      "/assets/stickers/sticker_22.svg",
      "/assets/stickers/sticker_23.svg",
      "/assets/stickers/sticker_24.svg",
      "/assets/stickers/sticker_25.svg",
      "/assets/stickers/sticker_26.svg",
      "/assets/stickers/sticker_27.svg",
      "/assets/stickers/sticker_28.svg",
      "/assets/stickers/sticker_29.svg",
      "/assets/stickers/sticker_30.svg",
      "/assets/stickers/sticker_31.svg",
      "/assets/stickers/sticker_32.svg",
    ],
  }),
  computed: {
    scores() {
      const lookup = {};
      for (let i = 0; i <= this.roundNumber; i++) {
        if (this.roundWinners.get(i)) {
          const winnerId = this.roundWinners.get(i);
          if (!lookup[winnerId]) {
            lookup[winnerId] = 1;
          } else {
            lookup[winnerId] += 1;
          }
        }
      }
      return lookup;
    },
    lastRoundWinner() {
      if (this.roundNumber > 0) {
        // Return last winner
        return this.roundWinners.get(this.roundNumber - 1);
      }
      return null;
    }
  },
  watch: {
    playerContext(newPlayerContext) {
      this.updatePlayerContext(newPlayerContext);
    },
    roundNumber(roundNumber) {
      const roomId = this.playerContext.getRoomId()
      const key = `${roomId}-hottips`
      const gameHotTips = localStorage.getItem(key)
      const hotTipsObj = JSON.parse(gameHotTips)
      const actualRoundNumber = roundNumber + 1
      const isGameInProgress = actualRoundNumber > 0 && actualRoundNumber <= this.numRounds

      // If it's expired, delete it from localStorage
      if (hotTipsObj && Date.parse(hotTipsObj.expiry) < new Date()) {
        localStorage.removeItem(key)
        return
      }

      if (!isGameInProgress) return

      const hottip = this.currentRound.getHottip()
      const prompt = this.currentRound.getPrompt()

      // If it exists in localStorage already, update it
      if (hotTipsObj) {
        if (hotTipsObj[actualRoundNumber]) {
          return
        }

        hotTipsObj[actualRoundNumber] = { hottip, prompt }

        localStorage.setItem(key, JSON.stringify(hotTipsObj))
      }

      // if it doesn't exist, add it
      if (!gameHotTips) {
        const today = new Date();
        const expiry = today.setHours(today.getHours() + 2);
        const newGameHotTips = {
          expiry
        }
        newGameHotTips[actualRoundNumber] = { hottip, prompt }

        localStorage.setItem(key, JSON.stringify(newGameHotTips))
      }
    }
  },
  methods: {
    updatePlayerContext: function (newPlayerContext) {
      console.log(newPlayerContext.toObject());
      window.currentContext = newPlayerContext;
      this.playerId = newPlayerContext.getSlot();
      this.playerAvatars = newPlayerContext.getPlayersMap();
      this.players = (this.playerAvatars && this.playerAvatars.keys) ? Array.from(this.playerAvatars.keys()) : [];
      this.numRounds = newPlayerContext.getNumRounds();
      this.ready = false;
      this.roundWinners = newPlayerContext.getRoundWinnersMap();

      if (newPlayerContext.getCurrentRound()) {
        this.currentRound = newPlayerContext.getCurrentRound();
        this.roundNumber = newPlayerContext.getCurrentRound().getRoundNumber();
        this.submissions = newPlayerContext
          .getCurrentRound()
          .getSubmissionsMap();
        if (this.submissions) {
          const mySubmission = this.submissions.get(this.playerId);
          if (mySubmission == "submitted") {
            this.ready = true;
          }
        }
        this.roundSticker = this.roundStickers[this.roundNumber];
        this.roundWinner = this.roundWinners.get(this.roundNumber);
      } else {
        this.roundSticker = null;
        this.roundWinner = null;
        // GAME OVER
        const scores = {};
        newPlayerContext.getRoundWinnersMap().forEach(function (winningSlot) {
          scores[winningSlot] = scores[winningSlot] || 0;
          scores[winningSlot]++;
        });
        let winner = 0;
        let winningScore = 0;
        this.players.forEach((playerSlot) => {
          if (scores[playerSlot] > winningScore) {
            winner = playerSlot;
            winningScore = scores[playerSlot];
          }
        });

        this.winnerId = winner;
        this.currentRound = null;
        this.roundNumber = 1000;
      }
    },
    chooseAvatar: function (avatarId) {
      console.log("Trying to choose avatar ", avatarId);
      try {
        const submission = new Submission();
        submission.setJudgement(avatarId);
        submission.setRoundNumber(-1);
        this.$emit('submit', submission);
      } catch (e) {
        console.log("Ugh, failed to select the avatar!", e);
      }
    },

    isReady: function () {
      console.log("READY TO GO!");
      this.ready = true;
      try {
        const submission = new Submission();
        // Append locale code to the "ready" message like "ready_en" or "ready_fr-CA"
        // get locale from i18n
        const locale = this.$i18n.locale;
        console.log("🌎🌎🌎 ready locale: ", locale)
        submission.setText("ready_" + locale);
        submission.setRoundNumber(-1);
        this.$emit('submit', submission);
      } catch (e) {
        this.ready = false;
        console.log("oops, error setting ready:", e);
      }
    },
    doSubmit(submission) {
      this.$emit('submit', submission);
      this.lastSubmit = this.roundNumber;
    },
    goHome() {
      this.$router.push("/");
    },
    newGame() {
      this.$emit('newGame');
    },
    mainPage() {
      this.$emit('mainPage');
    }
  },
  mounted() {
    // Generate stickers for each round
    for (let i = 0; i <= 12; i++) {
      this.roundStickers.push(this.stickers[Math.floor(Math.random() * this.stickers.length)]);
      console.log(this.roundStickers[i]);
      console.log(this.roundStickers);
    }
    console.log("Round Stickers");
    console.log(this.roundStickers);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.game {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow: hidden;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
