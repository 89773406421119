<template>
  <div class="gallery__responses">
    <div v-for="player in players" v-bind:response="submissions.get(player)" v-bind:key="player"
      class="gallery__response" :class="submissions.get(player) && 'gallery__response__ready'">
      <Avatar :avatar-id="playerAvatars.get(player)" class="gallery__avatar" />
      <div class="gallery__text">
        <template v-if="submissions.get(player)">
          <p class="play__text-submission">{{ submissions.get(player) }}</p>
        </template>
        <template v-else>
          <IconEllipsis />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import IconEllipsis from "../../icons/IconEllipsis";
import Avatar from "../../Avatar";
import { audioPlayer } from "/src/soundfx/audioPlayer.js";

export default {
  name: "TextGallery",
  mixins: [audioPlayer],
  components: {
    Avatar,
    IconEllipsis,
  },
  props: {
    submissions: Object,
    players: Array,
    playerAvatars: Object,
  },
  watch: {
    submissions: function () {
      this.playSound(this.soundConsts.OTHERS_SUBMISSION)
    },
  }
};
</script>

<style lang="scss" scoped>
.play-gallery {
  .gallery__responses {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
    max-height: 560px;
    max-width: 1200px;
    margin: 0;
    width: 100%;

    @media only screen and (min-width: 701px) {
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
    }
  }

  .gallery__response {
    position: relative;
    font-size: 14px;
    opacity: 0.5;
    height: 45%;
    max-width: 326px;

    @media only screen and (min-width: 701px) {
      width: 30%;
      margin: 0;
    }

    &.gallery__response__ready {
      opacity: 0.74;

      &.readyForJudgement {
        opacity: 1;

        >div {
          box-shadow: 0 0 0 2px var(--navy);
        }
      }
    }
  }

  .gallery__response:first-child:nth-last-child(n + 2),
  .gallery__response:first-child:nth-last-child(n + 2)~div {
    flex-basis: 100%;
    height: 45%;

    @media only screen and (min-width: 700px) {
      flex-basis: 45%;
    }
  }

  .gallery__response:first-child:nth-last-child(n + 3),
  .gallery__response:first-child:nth-last-child(n + 3)~div {
    flex-basis: 44%;

    @media only screen and (min-width: 700px) {
      flex-basis: 46%;
      margin: 0 10px 10px;
    }
  }

  .gallery__response:first-child:nth-last-child(n + 5),
  .gallery__response:first-child:nth-last-child(n + 5)~div {
    flex-basis: 40%;
    height: 30%;
    margin: 0 10px 15px;


    @media only screen and (min-width: 701px) {
      flex-basis: 28%;
      height: 44%;
    }
  }




  .gallery__text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: var(--spacing-small);
    margin: 0;
    border-radius: var(--radius-small);
    text-align: center;
  }

  .gallery__avatar {
    position: absolute;
    top: -12px;
    left: -12px;

    @media only screen and (min-width: 701px) {
      top: -18px;
      left: -18px;
    }
  }
}
</style>
