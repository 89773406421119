<template>
  <!--
  CHOICE
  For players of the round
  -->
  <div class="play light-forest-bg">
    <GameHeader :round-number="roundNumber" :timer-max="60" :end-time="submitEndTime" :num-rounds="numRounds" />
    <!-- <RoundStatus>
      <LowTimeWarning :text="$cms.alerts['Choose']" :warning="$cms.alerts['Time']" :threshold="5"
        :end-time="submitEndTime" />
    </RoundStatus> -->

    <div class="Choice">
      <div class="Choice__Prompt">
        <p>{{ topText }}</p>
      </div>
      <component :is="roundType == 'gif' ? 'GifChoice' : 'TextChoice'" :category="category" :players="players"
        :responses="responses" :round-number="roundNumber" :submit="submit" :top-text="topText" :num-rounds="numRounds" />
    </div>
  </div>
</template>

<script>
import TextChoice from "./TextChoice";
import GifChoice from "./GifChoice";
import GameHeader from "../game-header";
import RoundStatus from "../RoundStatus";


export default {
  name: "Choice",
  components: {
    GifChoice,
    GameHeader,
    TextChoice,
    RoundStatus
  },
  props: {
    category: String,
    roundNumber: Number,
    roundType: String,
    numRounds: Number,
    submitEndTime: Date,
    showTimer: Boolean, // TODO: make a component
    playerAvatars: Object,
    playerId: Number,
    players: Array,
    responses: Array,
    topText: String,
    judgeId: Number,
    submit: Function,
  },
  computed: {},
  methods: {},
  mounted() {
    this.$gtm.trackEvent({
      event: 'game_round_view',
      number_players: this.players.length,
      round_number: this.roundNumber,
      round_name: this.roundNumber === this.numRounds ? 'final round' : this.roundNumber,
      scenario_category: this.category,
      scenario_text: this.topText,
    });
  }
};
</script>

<style lang="scss" scoped>
.Choice {
  padding: var(--spacing) var(--spacing-medium);
  flex: auto;
  overflow: hidden;
  overflow-y: auto;

  &__Prompt {
    margin: 0 auto;
    text-align: center;
    padding: 24px 20px;
    line-height: 20px;
    border-radius: 12px;
    background-color: rgba(#FBFCF5, .2);
    margin-bottom: 22px;

    @media only screen and (min-width: 701px) {
      margin-top: var(--spacing);
      margin-bottom: var(--spacing-medium);
    }

    @media only screen and (min-width: 901px) {
      margin-top: var(--spacing-large);
    }

    p {
      font-size: 16px;
      margin: 0 auto;
      max-width: 23em;

      @media only screen and (min-width: 701px) {
        font-size: 21px;
        line-height: 1.4;
      }

      @media only screen and (min-width: 901px) {
        font-size: 25px;
      }
    }
  }
}
</style>
