<template>
  <div class="full-room">
    <Header />
    <div class="full-room__body">
      <p>
        {{ $t('sorry-the-room-you-tried-to-join-is-full-how-about-starting-your-own-game') }}
      </p>
      <button @click="mainPage" class="button">
        <img src="/assets/ui_elements/letsPlayWelcomeDesktop.svg" />
      </button>
    </div>
  </div>
</template>

<script>
import Header from "./header";

export default {
  name: "FullRoom",
  components: {
    Header,
  },
  emits: ["mainPage"],
  methods: {
    mainPage: function () {
      this.$emit('mainPage');
    }
  }
};
</script>

<style lang="scss" scoped>
.full-room {
  color: var(--white);
  background-color: var(--navy);
  /* min-height: calc(100vh - var(--header-height-desktop)); */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  min-height: 100vh;

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  p {
    max-width: 60%;
    font-size: 22px;
    line-height: 1.4;
    font-weight: 700;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;

    @media only screen and (min-width: 701px) {
      font-size: 30px;
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }

  .button {
    width: 100%;
    max-width: 300px;

    img {
      width: 100%;
    }
  }
}
</style>
