<template>
  <div class="onboarding">
    <Onboarding1 v-if="page == 1" @next-page="nextPage" @prev-page="prevPage" @reset-page="resetPage" />
    <Onboarding2 v-if="page == 2" @next-page="nextPage" @prev-page="prevPage" @reset-page="resetPage" />
    <Onboarding3 v-if="page == 3" @close-onboarding="emitCloseOnboarding" @prev-page="prevPage" @reset-page="resetPage" />

    <div v-if="page == 0">
      <header class="onboarding__header">
        <img src="/assets/logos/logo-likely-story.svg" id="logo" />
        <Sticker image="/assets/ui_elements/eyesSticker.svg" id="eyesSticker" :delay="0.15" />
        <Sticker image="/assets/ui_elements/phoneSticker.svg" id="phoneSticker" :delay="0.15" />
      </header>

      <div class="onboarding__hero">
        <div class="onboarding__heading">
          {{ $t('find-out-what-your-friends-think-is-normal-about') }} <strong>{{ $t('love-and-sex') }}</strong>{{
            $t('and-learn-how-to-prep-for') }}
          <strong class="underline">{{ $t('sketchy-scenarios') }}</strong>
        </div>
        <div class="onboarding__subheading">
          {{ $t('youve-been-invited-to-a-game-of-likely-story-learn-to-play-or-join-the-game') }}
        </div>
      </div>

      <footer class="onboarding__footer">
        <Button class="primary" @click="$emit('closeOnboarding')"
          @mousedown="playSound(soundConsts.START_A_GAME_BUTTON)">{{ $t('join-the-game') }}</Button>
        <Button class="secondary" @click="nextPage()" @mousedown="playSound(soundConsts.HOW_TO_PLAY_BUTTON)">{{
          $t('how-to-play') }}</Button>
      </footer>
    </div>
  </div>
</template>

<script>
import Button from "../buttons/Button";
import Onboarding1 from "./Onboarding1";
import Onboarding2 from "./Onboarding2";
import Onboarding3 from "./Onboarding3";
import Sticker from "../stickers/Sticker";
import { audioPlayer } from "/src/soundfx/audioPlayer.js";

export default {
  name: "Onboarding",
  mixins: [audioPlayer],
  components: {
    Button,
    Onboarding1,
    Onboarding2,
    Onboarding3,
    Sticker,
  },
  props: { roomCode: String },
  data: () => ({
    page: 0,
  }),
  emits: ["closeOnboarding"],
  methods: {
    emitCloseOnboarding: function () {
      this.$emit('closeOnboarding');
    },
    nextPage: function () {
      if (this.page == 0) {
        this.$gtm.trackEvent({
          event: 'game_instructions_cta',
          module_name: 'invite',
          click_text: 'How to play'
        });
      } else {
        this.$gtm.trackEvent({
          event: 'game_instructions_nav',
          module_name: 'invite',
          click_text: 'next'
        });
      }

      this.page += 1;
    },
    prevPage: function () {
      this.$gtm.trackEvent({
        event: 'game_instructions_nav',
        module_name: 'invite',
        click_text: 'back',
      });

      this.page -= 1;
    },
    resetPage: function () {
      this.$gtm.trackEvent({
        event: 'game_instructions_nav',
        module_name: 'invite',
        click_text: 'close',
      });

      this.page = 0;
    },
  },
}
</script>

<style lang="scss" scoped>
.onboarding {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--light-cream);
  z-index: 1000;
  overflow-y: auto;

  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: var(--spacing-large) 0;
    overflow: hidden;


    @media screen and (max-width: 700px) {
      padding: var(--spacing-medium) 0 var(--spacing);
    }
  }

  &__hero {
    flex: auto;
    padding: var(--spacing-medium);
    text-align: center;
    width: 100%;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;

    strong {
      font-weight: 700;
    }

    .underline {
      background-image: url("/assets/ui_elements/underline.svg");
      background-repeat: no-repeat;
      background-position: center bottom;
      padding-bottom: 0.3em;
    }
  }

  &__heading {
    font-size: 22px;
    line-height: 1.4;
    margin-bottom: 2em;
  }

  &__subheading {
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 1em;
  }

  &__footer {
    flex: 0;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    padding: var(--spacing) var(--spacing) var(--spacing-large);
  }
}

#logo {
  width: 148px;

  @media screen and (max-width: 700px) {
    width: 100px;
  }
}

#eyesSticker {
  width: 100px;
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, 5%);

  @media screen and (max-width: 700px) {
    width: 80px;
  }
}

#phoneSticker {
  width: 100px;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(-50%);

  @media screen and (max-width: 700px) {
    width: 80px;
  }
}
</style>