<template>
  <!--
    FINAL GALLERY
    After all answers have been submitted and winner has been chosen and signifies the end of a round.
  -->
  <div class="final-gallery pink-bg">
    <div class="final-gallery__status">
      <Transition name="popleave" appear>
        <div v-if="!showSummary">
          <div class="avatar">
            <Avatar :avatar-id="playerAvatars.get(winnerId)" />
          </div>
        </div>
      </Transition>
      <div class="text">
        <template v-if="playerId === winnerId">{{ $t('you-won') }}</template>
        <template v-else>{{ $t('player-winnerid-won', [winnerId]) }}</template>
      </div>
      <div class="sticker">
        <Transition name="flyin" appear>
          <Sticker :image="winnerSticker" id="winnerSticker" :delay="0.15" v-if="!showSummary" />
        </Transition>
        <Transition name="popleave" appear>
          <div class="sticker-point" v-if="!showSummary">{{ $t('1-point') }}</div>
        </Transition>
      </div>
    </div>

    <Transition name="slide-fade">
      <div class="final-gallery__summary" v-if="showSummary">
        <div class="final-gallery__scenario">
          {{ topText }}
        </div>
        <div class="final-gallery__response">
          <div class="avatar">
            <Avatar :avatar-id="playerAvatars.get(winnerId)" />
          </div>
          <p v-if="roundType == 'text'">{{ submissions.get(winnerId) }}</p>
          <img v-if="roundType == 'gif'" :src="submissions.get(winnerId)" class="final-gallery__gif" />
        </div>
      </div>
    </Transition>

    <HotTip v-if="showHotTip" v-bind:hotTip="hotTip" :round-number="roundNumber" :num-rounds="numRounds"
      :players="players" />
  </div>
</template>

<script>
import Avatar from "../Avatar";
import HotTip from "./HotTip.vue";
import Sticker from "../stickers/Sticker";
import { audioPlayer } from "/src/soundfx/audioPlayer.js";
import soundConsts from "../../soundfx/soundConsts";

export default {
  name: "FinalGallery",
  mixins: [audioPlayer],
  components: {
    Avatar,
    HotTip,
    Sticker,
  },
  data: () => ({
    showSummary: false,
    showHotTip: false,
    sticker: 50, // TODO figure out where sticker id comes from
  }),
  props: {
    category: String,
    didSubmit: Boolean,
    hotTip: String,
    judgeId: Number,
    playerAvatars: Object,
    playerId: Number,
    players: Array,
    roundNumber: Number,
    numRounds: Number,
    roundType: String,
    showTimer: Boolean,
    submissions: Object,
    topText: String,
    winnerId: Number,
    winnerSticker: String,
  },
  computed: {
    gif() {
      return this.roundType == "gif";
    },
    isWinner() {
      return this.playerId == this.winnerId;
    },
    playersNotJudge() {
      if (!this.players) {
        return [];
      }
      return this.players.filter((playerId) => playerId != this.judgeId);
    },
  },
  mounted() {
    let summaryTime = 3 * 1000;
    let hotTipTime = summaryTime + (5 * 1000);
    this.playSound(soundConsts.WINNER_STICKER)

    setTimeout(() => {
      this.playSound(soundConsts.WINNERS_RESPONSE)
      this.showSummary = true;
    }, summaryTime);

    setTimeout(() => {
      this.playSound(soundConsts.HOT_TIPS)
      this.showHotTip = true;
    }, hotTipTime);

    if (this.judgeId == this.playerId && !this.didSubmit) {
      // TODO: GTM event for auto-judgement
    }

    // Google Tag Manager Event
    this.$gtm.trackEvent({
      event: 'game_round_complete',
      round_number: this.roundNumber,
      round_name: this.roundNumber === this.numRounds ? 'final round' : this.roundNumber,
      scenario_category: this.category,
      scenario_text: this.topText,
      // round_complete_method: '',
      winning_response: this.submissions.get(this.winnerId),
      number_players: this.players.length,
    });
  },
};
</script>

<style lang="scss" scoped>
.final-gallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: var(--spacing-medium);

  &__card {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: var(--radius-medium);

    >.text {
      padding: var(--spacing-medium) var(--spacing);
      color: var(--navy);

      @media only screen and (min-width: 701px) {
        width: 500px;
      }
    }
  }

  &__status {
    @extend .final-gallery__card;
    position: relative;

    .text {
      font-size: 28px;
      font-weight: 700;
      text-align: center;
    }

    .avatar {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-25%, -66%);
      padding: 15px;
    }

    .sticker {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 120px;
      transform: translate(25%, 66%);
    }

    .sticker-point {
      position: absolute;
      font-size: 20px;
      font-weight: 700;
      transform: rotate(12.98deg) translate(0, 50%);
    }

    @media only screen and (min-width: 701px) {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  //__status

  &__summary {
    @extend .final-gallery__card;
    overflow: hidden;
    margin-top: var(--spacing-small);

    @media only screen and (min-width: 701px) {
      max-width: 500px;
      margin: var(--spacing-small) auto 0;
    }
  }

  //__summary

  &__scenario {
    padding: var(--spacing-medium) var(--spacing) var(--spacing-large);
    font-size: 18px;
    font-weight: 700;
    line-height: 1.234;
    color: var(--navy);
    text-align: center;
  }

  &__response {
    position: relative;
    padding: var(--spacing-medium) var(--spacing) var(--spacing-medium);
    font-size: 18px;
    font-weight: 800;
    line-height: 1.344;
    text-align: center;
    background-color: var(--white);
    color: var(--red);

    @media only screen and (min-width: 701px) {
      padding: var(--spacing-large) var(--spacing) var(--spacing-large);
    }

    .avatar {
      position: absolute;
      width: 74px !important;
      height: 74px !important;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 48px !important;
      }
    }
  }

  .avatar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    border-radius: 45px;
    background-color: var(--white);

    img {
      width: 70px;
    }
  }

  &__gif {
    width: 150px;
    height: auto;
    margin-top: var(--spacing-small);
  }
}

// .final-gallery

#winnerSticker {
  max-width: 100px;
  max-height: 100px;
  object-fit: contain;
}

/* Transitions */
.popleave-enter-active,
.popleave-leave-active {
  transition: opacity 0.33s $ease-3 0.33s, transform 0.33s $ease-3 0.33s;
  transform: scale(1);
  opacity: 1;
}

.popleave-enter-from,
.popleave-leave-to {
  opacity: 0;
  transform: scale(1.15);
}

.flyin-enter-active,
.flyin-leave-active {
  transition: opacity 0.33s $ease-3 0.33s, transform 0.33s $ease-3 0.33s;
  transform: scale(1);
  opacity: 1;
}

.flyin-enter-from,
.flyin-leave-to {
  opacity: 0;
  transform: scale(3);
}



.slide-fade-enter-active {
  transition: all 0.3s $ease-3 0.33s;
}

.slide-fade-leave-active {
  transition: all 0.8s $ease-3 0.33s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
