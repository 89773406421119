<template>
  <!--
    GALLERY
    Wait screen if you have already submitted answer.
    Shows all answers that have been submitted along with who submitted them.
  -->
  <div class="play play-gallery player-bg">

    <GameHeader v-if="!readyForJudgement" :round-number="roundNumber" :timer-max="60" :end-time="submitEndTime"
      :num-rounds="numRounds" />
    <GameHeader v-if="readyForJudgement" :round-number="roundNumber" :timer-max="30" :end-time="judgeEndTime"
      :num-rounds="numRounds" />

    <main class="play-gallery__body">
      <RoundStatus class="top">
        <div>{{ $t('the-judge-is-picking-a-winner') }}</div>
      </RoundStatus>
      <div class="play-gallery__text">
        <p>{{ topText }}</p>
      </div>
      <TextGallery v-if="roundType == 'text'" :players="playersNotJudge" :player-avatars="playerAvatars"
        :submissions="submissions" />
      <GifGallery v-if="roundType == 'gif'" :players="playersNotJudge" :player-avatars="playerAvatars"
        :submissions="submissions" />
    </main>
  </div>
</template>

<script>
import GifGallery from "./GifGallery";
import RoundStatus from "../RoundStatus";
import TextGallery from "./TextGallery";
import GameHeader from "../game-header";

export default {
  name: "Gallery",
  components: {
    GameHeader,
    GifGallery,
    RoundStatus,
    TextGallery,
  },
  props: {
    didSubmit: Boolean,
    roundNumber: Number,
    roundType: String,
    numRounds: Number,
    playerId: Number,
    judgeEndTime: Date,
    judgeId: Number,
    submitEndTime: Date,
    showTimer: Boolean,
    topText: String,
    submissions: Object,
    players: Array,
    playerAvatars: Object,
  },
  computed: {
    playersNotJudgeForRead() {
      if (this.players) {
        return this.players.filter((x) => x != this.judgeId);
      } else {
        return [];
      }
    },
    readyForJudgement() {
      let ready = true;
      this.playersNotJudgeForRead.forEach((player) => {
        ready = ready && this.submissions.get(player);
      });
      return !!ready;
    },
    roundColor() {
      switch (this.roundType) {
        case "text":
          return "light-pink-bg";
        case "gif":
          return "light-forest-bg";
        default:
          return "";
      }
    },
    playersNotJudge() {
      return this.players.filter((p) => p != this.judgeId);
    },
  },
  mounted() {
    if (this.playerId != this.judgeId && !this.didSubmit) {
      // TODO: GTM event for auto-submit
    }
  },
};
</script>

<style lang="scss" scoped>
.play-gallery {
  &__body {
    display: flex;
    flex-direction: column;
    padding: var(--spacing);
    flex: 1 1 auto;
  }

  &__text {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 20px;
    transition: all 0.33s $ease-3 0.33s;

    padding: 24px 20px;
    line-height: 20px;
    border-radius: 12px;
    background-color: rgba(#FBFCF5, .2);

    p {
      margin: 0 auto;
    }
  }
}
</style>
