<template>
  <Splash :judge-id="judgeId" :player-avatars="playerAvatars" :player-id="playerId" :round-number="roundNumber"
    :top-text="topText" v-if="showSplash" />
  <component :is="activeMode" :category="category" :round-number="roundNumber" :num-rounds="numRounds"
    :round-type="roundType" :submit-end-time="submitEndTime" :show-timer="showTimer" :player-id="playerId"
    :player-avatars="playerAvatars" :responses="responses" :top-text="topText" :judge-id="judgeId" :submit="submit"
    v-else />
</template>

<script>
import Choice from "./choice";
import Judgement from "./judgement";
import Splash from "./Splash";
import { audioPlayer } from "/src/soundfx/audioPlayer.js";

export default {
  name: "Question",
  mixins: [audioPlayer],
  components: {
    Choice,
    Judgement,
    Splash,
  },
  data: () => ({
    showSplash: true,
  }),
  props: {
    category: String,
    judgeId: Number,
    playerAvatars: Object,
    playerId: Number,
    responses: Array,
    roundNumber: Number,
    numRounds: Number,
    roundType: String,
    showTimer: Boolean,
    submit: Function,
    submitEndTime: Date,
    topText: String,
  },
  created() {
    let pageName = 'game round ' + this.roundNumber;
    
    this.$gtm.trackEvent({
      event: 'virtual_page_view',
      page_name: pageName,
      page_url: window.location.href,
    });
  },
  mounted() {
    let splashTime = 10 * 1000;
    setTimeout(() => {
      this.showSplash = false;
    }, splashTime);
  },
  computed: {
    activeMode() {
      if (this.judgeId == this.playerId) {
        return Judgement;
      } else {
        return Choice;
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>