<template>
  <div class="RoundStatus">
    <slot />
  </div>
</template>

<script>
export default {
  name: "RoundStatus"
}
</script>

<style lang="scss" scoped>
.RoundStatus {
  width: 100%;
  padding: var(--spacing-small) var(--spacing);
  font-weight: 700;
  background-color: var(--forest);
  color: var(--white);
  text-align: center;
  border-radius: 100px;
  font-size: 14px;
  line-height: 1.3;

  @media only screen and (min-width: 701px) {
    font-size: 22px;
    padding: 16px;
  }

  &.top {
    margin-top: -6px;
    margin-bottom: 20px;
  }

  .play-judge & {
    background-color: var(--navy);

    &.green {
      color: var(--navy);
      background-color: var(--green);
    }
  }
}
</style>