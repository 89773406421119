<template>
  <div>
    <div class="game-header">
      <div class="round">
        {{ $t('round-roundnumber-numrounds', [roundNumber, numRounds]) }}
      </div>
      <div class="right">
        <SoundControl />
        <Timer :max="timerMax" :end-time="endTime" />
      </div>
    </div>
    <div class="progress-bar">
      <span class="progress" :style="{ 'width': `${roundProgressPercentage}%` }" />
    </div>
  </div>
</template>

<script>
import Timer from "../../util/Timer";
import SoundControl from "../../Soundcontrol/SoundControl";

export default {
  name: "GameHeader",
  components: { Timer, SoundControl },
  props: {
    roundNumber: Number,
    endTime: Date,
    numRounds: Number,
    timerMax: Number,
  },
  computed: {
    roundProgressPercentage() {
      return (this.roundNumber / this.numRounds) * 100
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.popup-control) {
  margin-right: 8px;

  @media only screen and (min-width: 701px) {
    margin-right: 16px;
  }
}

:deep(.sound-modal) {
  transform: translate(30%, 100%);
}

.game-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;

  .round {
    color: var(--navy);
    font-weight: 520;
    font-size: 18px;
    line-height: 26px;
    font-family: 'cheee';

    @media only screen and (min-width: 701px) {
      font-size: 24px;
    }
  }

  .right {
    display: flex;
    align-items: center;

  }
}

.progress-bar {
  height: 12px;
  background-color: var(--white);
  position: relative;

  .progress {
    position: absolute;
    background-color: var(--navy);
    height: 100%;

    width: 50%;
  }
}
</style>
