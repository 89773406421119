<template>
  <!--
  JUDGEMENT
  Just for the judge of the round
  -->
  <div class="play play-judge light-green-bg">

    <div v-if="!splashShown">
      <GameHeader v-if="!readyForJudgement" :round-number="roundNumber" :timer-max="60" :end-time="submitEndTime"
        :num-rounds="numRounds" />
      <GameHeader v-if="readyForJudgement" :round-number="roundNumber" :timer-max="30" :end-time="judgeEndTime"
        :num-rounds="numRounds" />
    </div>

    <div class="judge__body">

      <transition name="splashJudge" show>
        <div v-if="splashShown" class="judge__splash">
          <div class="judge__splash-body">{{ $t('youre-the-judge-this-round') }}</div>
        </div>
      </transition>

      <transition name="mainJudge" show>
        <main v-if="!splashShown" class="game-body">
          <RoundStatus v-if="!readyForJudgement" class="top green">
            <div>
              {{ $cms.alerts['Waiting'] }}
            </div>
          </RoundStatus>
          <div class="play__text">
            <p>{{ topText }}</p>
          </div>
          <TextJudgement v-if="roundType == 'text'" :players="playersNotMe" :submissions="submissions"
            :ready-for-judgement="readyForJudgement" :submit="submitJudgement" />
          <GifJudgement v-else-if="roundType == 'gif'" :players="playersNotMe" :submissions="submissions"
            :ready-for-judgement="readyForJudgement" :submit="submitJudgement" />
          <IconEllipsis v-else />

          <RoundStatus v-if="readyForJudgement">
            <LowTimeWarning :text="$cms.alerts['SelectWinner']" :warning="$cms.alerts['Time']" :threshold="5"
              :end-time="judgeEndTime" />
          </RoundStatus>
        </main>
      </transition>

    </div>
  </div>
</template>

<script>
import GifJudgement from "./GifJudgement";
import IconEllipsis from "../../icons/IconEllipsis";
import { Submission } from "../../../proto/game_pb";
import TextJudgement from "./TextJudgement";
import { audioPlayer } from "/src/soundfx/audioPlayer.js";
import GameHeader from "../game-header";
import RoundStatus from "../RoundStatus";
import LowTimeWarning from '../../util/LowTimeWarning'


export default {
  name: "Judgement",
  mixins: [audioPlayer],
  components: {
    GifJudgement,
    GameHeader,
    IconEllipsis,
    TextJudgement,
    RoundStatus,
    LowTimeWarning
  },
  props: {
    category: String,
    playerId: Number,
    roundNumber: Number,
    numRounds: Number,
    roundType: String,
    topText: String,
    judgeEndTime: Date,
    players: Array,
    playerAvatars: Object,
    submissions: Object,
    submit: Function,
    submitEndTime: Date,
  },
  computed: {
    readyForJudgement() {
      let ready = true;
      this.playersNotMe.forEach((player) => {
        ready = ready && this.submissions.get(player);
      });
      return !!ready;
    },
    playersNotMe() {
      if (this.players) {
        return this.players.filter((x) => x != this.playerId);
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      splashShown: true,
      splashTimer: 5000,
    };
  },
  methods: {
    submitJudgement(slot) {
      this.playSound(this.soundConsts.WINNER_SELECTED)
      const submission = new Submission();
      submission.setJudgement(slot);
      this.submit(submission);
    },
  },
  mounted() {
    this.$gtm.trackEvent({
      event: 'game_round_view',
      number_players: this.players.length,
      round_number: this.roundNumber,
      round_name: this.roundNumber === this.numRounds ? 'final round' : this.roundNumber,
      scenario_category: this.category,
      scenario_text: this.topText,
    });

    this.playSound(this.soundConsts.YOURE_THE_JUDGE)
    setTimeout(() => {
      this.splashShown = false;
    }, this.splashTimer);
  },
};
</script>

<style lang="scss">
.play {
  display: flex;
  flex-direction: column;
  padding-bottom: 0 !important;
}

.header {
  flex: 0 1 auto;
}

.play__text {
  margin-left: auto;
  margin-right: auto;
  transition: all 0.33s $ease-3 0.33s;
  text-align: center;
  margin-bottom: 20px;
  padding: 24px 20px;
  line-height: 20px;
  border-radius: 12px;
  background-color: rgba(#FBFCF5, .2);

  p {
    margin: 0 auto;
  }
}

.judge {
  transition: all 1s $ease-3;

  &__body {
    position: relative;
    flex: auto;
    display: flex;
  }
}

.judge__splash {
  flex: 1 1 auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--navy);
  background-image: url("/assets/backgrounds/bg-navy.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--white);
  padding: var(--spacing-large);
  z-index: 1;

  &-body {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.4;
    text-align: center;
  }
}

.splashJudge-enter-from {
  opacity: 0;

  .splash-avatar,
  .judge__splash-body {
    opacity: 0;
    transform: scale(1.5);
  }
}

.splashJudge-enter-active,
.splashJudge-leave-active {
  transition: opacity 0.33s $ease-3;

  .judge__splash-body {
    transition: all 0.16s $ease-3;
  }
}

.splashJudge-enter-to,
.splashJudge-leave-from {
  opacity: 1;
  flex: 1;
  transform-origin: top;
  bottom: 20vh;

  .judge__splash-body {
    opacity: 1;
    transform: scale(1);
  }
}

.splashJudge-leave-to {
  opacity: 0;

  .judge__splash-body {
    transform: scale(0);
  }
}

.mainJudge-enter-from,
.mainJudge-leave-to {
  opacity: 0;

  .play__text {
    opacity: 0;
    transform: translateY(60px);
  }

  .judge {
    transform: translateX(110%);
  }
}

.mainJudge-enter-active,
.mainJudge-leave-active {
  transition: opacity 0.16s $ease-3 0.33s;
}

.mainJudge-enter-to,
.mainJudge-leave-from {
  opacity: 1;

  .play__text {
    opacity: 1;
    transform: translateY(0);
  }

  .judge {
    transform: translateX(0);
  }
}
</style>
